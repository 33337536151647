import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetFeedsManager = (searchQuery = "") => {
  return useQuery(["feeds", searchQuery], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(`/feed/posts${searchQuery}`),
      ];
      console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetFeedsManager;
