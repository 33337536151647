import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import AllProfilesTable from "../../../../generalComponents/AllProfilesTable";
import { useNavigate, useParams } from "react-router-dom";
import useGetSingleUserManager from "../controllers/getSingleUserController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import GlobalVariables from "../../../../constants/GlobalVariables";
import { AiOutlineArrowLeft } from "react-icons/ai";
import useGetAllProfilesManager from "../../profiles/controllers/getAllProfilesController";
import { Pagination } from "@mui/material";

const UserDetailsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { userId } = useParams();
  const {
    isError: profileshasError,
    isLoading: profileLoading,
    data: profileData,
    error: profileError,
    refetch,
  } = useGetAllProfilesManager(currentPage, false, true, userId, searchQuery);

  const handlePageChange = (page) => {
    if (page === currentPage) return; // No action needed if already on the current page

    if (page === data.data.pagination.nextPage) {
      // Move to the next page
      setCurrentPage(page);
      refetch();
    } else if (page === data.data.pagination.prevPage) {
      // Move to the previous page
      setCurrentPage(page);
      refetch();
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        setSearchQuery(`&fullname=${searchTerm}`);
        refetch();
      } else {
        setSearchQuery(""); // Clear the search query
        refetch();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);
  const { data, isLoading, isError, error } = useGetSingleUserManager(userId);

  if (isLoading || profileLoading) {
    return <Loader />;
  }

  if (isError || profileshasError) {
    return (
      <div>
        {isError && <ErrorManager errorMessage={error.message} />}
        {profileshasError && (
          <ErrorManager errorMessage={profileError.message} />
        )}
      </div>
    );
  }
  return (
    <BaseDashboardNavigation
      title={"User Details"}
      value={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        console.log(searchTerm);
      }}
    >
      <div className="md:h-[80vh] md:overflow-y-scroll mr-7  scrollbar-hide">
        <button
          onClick={() => window.history.back()}
          className="flex items-center space-x-2"
        >
          <AiOutlineArrowLeft />
          <p>Back</p>
        </button>
        {/* profile picture and current plan */}
        <div className="w-full flex flex-col md:flex-row items-center justify-between  px-2 md:px-8 mt-16 mx-auto">
          <div className=" flex items-center">
            <div className="h-[95px] md:h-[115px]  w-[95px] md:w-[115px] mr-3 md:mr-6">
              <img
                className=" w-full h-full rounded-full object-cover hover:scale-150 "
                src={
                  data.data.user.profile_picture !== ""
                    ? data.data.user.profile_picture
                    : GlobalVariables.defaultProfilePicture
                }
                alt=""
              />
            </div>
            <div>
              <p className="text-[30px] font-semibold ">
                {data.data.user.fullname}
              </p>
              <p className="text-[16px] font-normal ">{data.data.user.email}</p>
              <p className="text-[16px] font-normal ">{data.data.user.phone}</p>
            </div>
          </div>
          {/* subscription and plan */}
          <div className="group rounded-[20px] p-5 w-full md:w-[300px] bg-white hover:bg-offCoffee flex items-center border border-lightGrey shadow-xl mb-4 justify-between mt-6 md:mt-0 md:mr-16 text-blackColor group-hover:text-white">
            <div>
              <p className="text-16px font-bold ">
                {data.data.user.subscription[0].plan.name} Plan
              </p>
              <p className="text-13px ">
                {" "}
                {(
                  data.data.user.subscription[0].plan.price * 12 -
                  (data.data.user.subscription[0].plan.discount / 100) *
                    data.data.user.subscription[0].plan.price *
                    12
                ).toFixed(2)}{" "}
                billed yearly
              </p>
            </div>
            <p className="text-20px font-bold text-brandOrange group-hover:text-white">
              ${`${data.data.user.subscription[0].plan.price}`}{" "}
              <span className="text-13px text-blackColor group-hover:text-white">
                /mo
              </span>
            </p>
          </div>
        </div>

        {/* other personal data */}
        <div className="w-full flex flex-col md:flex-row items-center justify-between  px-2 md:px-8 mt-4 mx-auto">
          <div className=" flex flex-col items-center w-full md:max-w-[400px] ">
            <div className="flex items-center justify-between w-full">
              <p className="text-[16px] font-semibold ">
                Date of Birth:{" "}
                <span className="text-[16px] font-normal ">
                  {data.data.user.dob.month === ""
                    ? "No Birthdate"
                    : ` ${data.data.user.dob.day} ${data.data.user.dob.month}`}
                </span>
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-[16px] font-semibold ">
                Sex:{" "}
                <span className="text-[16px] font-normal ">
                  {data.data.user.sex === "" ? "No Sex" : data.data.user.sex}
                </span>
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-[16px] font-semibold ">
                Location:{" "}
                <span className="text-[16px] font-normal ">{`${data.data.user.city}, ${data.data.user.state}, ${data.data.user.country}`}</span>
              </p>
            </div>
          </div>
          {/* personal analytics*/}
          <div className="flex flex-col md:flex-row justify-between w-full md:w-auto mt-6 md:mt-0 text-white md:space-x-[13px] space-y-[13px] md:space-y-0 py-1 mb-[10px] md:mb-[10px] ">
            <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-offCoffee p-8 w-full  flex flex-col justify-center">
              <p className="text-30px font-semibold ">
                {data.data.user.profile.totalProfiles}
              </p>
              <p className="text-16px font-semibold ">Total Profiles</p>
            </div>
            <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-brandOrange p-8  w-full flex flex-col justify-center">
              <p className="text-30px font-semibold ">
                {data.data.user.profile.forYouProfiles}
              </p>
              <p className="text-16px font-semibold ">Total For You Profiles</p>
            </div>
          </div>
        </div>
        {/* divider */}
        <div className="divider"></div>
        {/* profile tables */}
        {profileData.data.profiles.length ? (
          <div>
            <div className="md:h-[50vh] relative md:overflow-y-auto scrollbar-hide">
              <AllProfilesTable profiles={profileData.data.profiles} />
            </div>
            {profileData.data.pagination.pageTotal && (
              <Pagination
                onChange={(event, page) => handlePageChange(page)}
                className="my-10 mx-auto w-fit bottom-0"
                count={profileData.data.pagination.pageTotal}
                variant="outlined"
                shape="rounded"
              />
            )}
          </div>
        ) : (
          <p className="mx-auto md:my-20 w-full  text-center">{`No profiles found for ${data.data.user.fullname} `}</p>
        )}
      </div>
    </BaseDashboardNavigation>
  );
};

export default UserDetailsPage;
