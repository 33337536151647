import React, { useEffect, useState } from "react";

import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import FeedTiles from "../../../../generalComponents/FeedTiles";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import BroadcastTile from "../../../../generalComponents/BroadcastTiles";
import useCreateBroadcastManager from "../controller/createBroadcastController";

import useGetListOfPlansManager from "../../pricing/controller/getListOfPlansController";
import useBroadcastListManager from "../controller/broadcastListController";
import useEditBroadcastManager from "../controller/editBroadcastController";
import useResendBroadcastManager from "../controller/resendBroadcastController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import CustomButton from "../../../../generalComponents/Button";

import { formatDistanceToNow } from "date-fns";
import { toast } from "react-toastify";

const BroadcastPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [currentBroadcastId, setCurrentBroadcastId] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);

  function formatDateAgo(date) {
    const options = { addSuffix: true };
    return formatDistanceToNow(new Date(date), options);
  }

  const handlePlanToggle = (planId) => {
    if (selectedPlans.includes(planId)) {
      setSelectedPlans(selectedPlans.filter((id) => id !== planId)); // Remove the plan
    } else {
      setSelectedPlans([...selectedPlans, planId]); // Add the plan
    }
  };
  const { isLoading, postCaller } = useCreateBroadcastManager();

  const {
    isError: plansHasError,
    data: plans,
    error: plansError,
    isLoading: plansLoading,
  } = useGetListOfPlansManager();
  const {
    isError: broadcastHasError,
    data: broadcasts,
    error: broadcastFetchError,
    isLoading: fetchingBroadcasts,
  } = useBroadcastListManager();

  const {
    isLoading: editingBroadcast,

    updateCaller,
  } = useEditBroadcastManager(currentBroadcastId);

  const {
    isLoading: resendingBroadcast,

    postCaller: resend,
  } = useResendBroadcastManager();

  if (plansLoading || fetchingBroadcasts) {
    return <Loader />;
  }

  if (plansHasError || broadcastHasError) {
    return (
      <div>
        {plansHasError && <ErrorManager errorMessage={plansError.message} />}
        {broadcastHasError && (
          <ErrorManager errorMessage={broadcastFetchError.message} />
        )}
      </div>
    );
  }
  return (
    <BaseDashboardNavigation title={"Broadcast"}>
      <div className="md:flex md:space-x-[20px] space-y-20 md:space-y-5 relative h-full w-full md:w-[98%]">
        {/* scroll feeds */}

        <div className="overflow-y-hidden md:overflow-y-auto  overflow-x-auto md:overflow-x-hidden flex flex-row md:flex-col md:gap-y-4 md:w-[25%] scrollbar-hide mb-[39px] relative md:h-[80vh]">
          {broadcasts.data && currentIndex < broadcasts.data.length ? (
            broadcasts.data.map((broadcast, index) => {
              return (
                <BroadcastTile
                  isCurrent={index === currentIndex}
                  broadcast={broadcast}
                  key={index}
                  onClick={() => {
                    setCurrentIndex(index);
                    setShowFeedback(false);
                  }}
                />
              );
            })
          ) : (
            <p className="text-blackColor">No broadcasts available</p>
          )}
        </div>

        {/* feed details */}
        {broadcasts.data && currentIndex < broadcasts.data.length && (
          <div className="w-full md:w-[45%] mb-[39px] md:h-[80vh]  overflow-y-auto scrollbar-hide ">
            <div className="mb-2 mr-7  md:mr-0">
              <p className="text-30px font-bold text-blackColor mb-3">
                {broadcasts.data[currentIndex].title}
              </p>
              <div className="md:flex items-center justify-between  mb-8 mr-8">
                <p className=" text-16px font-normal  mr-32">
                  {`Sent ${formatDateAgo(
                    broadcasts.data[currentIndex].createdAt
                  )}`}
                </p>
                <div className="md:flex items-center justify-end  mr-8 space-x-4">
                  <CustomButton
                    buttonText={edit ? "Cancel Edit" : "Edit"}
                    onClick={async () => {
                      if (edit) {
                        setEdit(false);
                        setTitle("");
                        setBody("");
                        setSelectedPlans([]);
                        setCurrentBroadcastId("");
                      } else {
                        setEdit(true);
                        setTitle(broadcasts.data[currentIndex].title);
                        setBody(broadcasts.data[currentIndex].body);
                        setCurrentBroadcastId(broadcasts.data[currentIndex].id);
                      }
                    }}
                  />
                  <CustomButton
                    buttonText={"Resend"}
                    isLoading={resendingBroadcast}
                    onClick={async () => {
                      await resend(broadcasts.data[currentIndex].id);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* recipients */}
            <div>
              {broadcasts.data[currentIndex].plan.map((recipient, index) => (
                <span className="bg-brandOrange rounded-full mr-2 py-1.5 px-4 mb-2 text-whiteColor">
                  {recipient.name}
                </span>
              ))}
            </div>

            {/* text area */}
            <div className=" rounded-[12px] p-5 md:p-7 mb-[24px] bg-lightGrey/20 mt-10  mr-7 ">
              <p className=" text-16px font-normal mb-3">
                {broadcasts.data[currentIndex].body}
              </p>
            </div>
          </div>
        )}

        {/* create or edit container */}
        <div className="rounded-[20px] max-w-full md:w-[30%]  mr-7 p-5 py-10 bg-lightGrey/20 md:h-[80vh]  overflow-y-auto scrollbar-hide ">
          <p className="text-18px mb-10 font-bold">Create/Edit Broadcast</p>

          {!edit && (
            <p className="text-13px md:text-16px font-semibold mb-2">
              Choose Recepients
            </p>
          )}
          {!edit && (
            <div className="mb-4">
              {plans.data.map((plan) => (
                <div
                  key={plan.id}
                  className="flex items-start justify-start h-7 "
                >
                  <InputWithFullBoarder
                    className={"mb-4 mr-3"}
                    type={"checkbox"}
                    checked={selectedPlans.includes(plan.id)}
                    value={plan.id}
                    onChange={(e) => handlePlanToggle(e.target.value)}
                  />
                  <p className="text-13px md:text-16px font-semibold mt-[2px]">
                    {`${plan.name} plan`}
                  </p>
                </div>
              ))}
            </div>
          )}

          <InputWithFullBoarder
            label={"Title"}
            className={"mb-4"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <InputWithFullBoarder
            label={"Description"}
            isTextArea={true}
            className={"mb-4"}
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />

          <CustomButton
            buttonText={edit ? "Edit Broadcast" : "Send Broadcast"}
            isLoading={isLoading || editingBroadcast}
            onClick={async () => {
              if (edit) {
                const details = {
                  title: title,
                  body: body,
                  isDraft: false,
                };
                setShowFeedback(true);
                await updateCaller(details);
                setEdit(false);
                setTitle("");
                setBody("");
                setSelectedPlans([]);
              } else {
                const details = {
                  title: title,
                  body: body,
                  isDraft: false,
                  plan: selectedPlans,
                };
                setShowFeedback(true);
                await postCaller(details);
                setTitle("");
                setBody("");
                setSelectedPlans([]);
              }
            }}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default BroadcastPage;
