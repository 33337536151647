import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import FeedTiles from "../../../generalComponents/FeedTiles";
import useGetFeedsManager from "../../admin/feed/controllers/getListOfFeedsController";
import Loader from "../../../generalComponents/Loader";
import ErrorManager from "../../../generalComponents/ErrorManager";
import { format } from "date-fns";

const AllFeedsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM d, yyyy");
  };

  const { isError, data, error, isLoading, refetch } =
    useGetFeedsManager(searchQuery);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        setSearchQuery(`?title=${searchTerm}`);
        refetch();
      } else {
        setSearchQuery(""); // Clear the search query
        refetch();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);
  const [currentIndex, setCurrentIndex] = useState(0);
  if (isLoading) {
    return <Loader />;
  }

  // if (isSuccess && currentPostId !== data.data[currentIndex].id) {
  //   setCurrentPostId(data.data[currentIndex].id);
  // }

  if (isError) {
    return (
      <div>
        {isError && <ErrorManager errorMessage={error.message} />}
        {/* {profileError && <ErrorManager errorMessage={error.deleteError} />} */}
      </div>
    );
  }
  return (
    <BaseDashboardNavigation
      title={"Feeds"}
      value={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        console.log(searchTerm);
      }}
    >
      {data.data.length ? (
        <div className="md:flex md:space-x-[46px] space-y-20 md:space-y-5 relative h-full w-full">
          {/* scroll feeds */}

          <div className="overflow-y-hidden md:overflow-y-auto  overflow-x-auto md:overflow-x-hidden flex flex-row md:flex-col md:gap-y-4 md:w-[25%] scrollbar-hide mb-[39px] relative md:h-[80vh]">
            {data.data.map((feed, index) => {
              return (
                <FeedTiles
                  feedDetail={feed}
                  isCurrent={index === currentIndex}
                  onClick={() => setCurrentIndex(index)}
                />
              );
            })}
          </div>

          {/* feed details */}
          <div className="w-full md:w-[70%] mb-[39px] md:h-[80vh]  overflow-y-auto scrollbar-hide ">
            <div className="mb-5 mr-7  md:mr-0">
              <p className="text-30px font-bold text-blackColor">
                {data.data[currentIndex].title}
              </p>
              <p className=" text-16px font-normal mb-3">
                {formatDate(data.data[currentIndex].schedule)}
              </p>
              <div className="flex space-x-1">
                {data.data[currentIndex].tags.map((tag) => (
                  <button className="bg-offCoffee rounded-[12px] font-medium py-0 px-4 text-blackColor">
                    {tag}
                  </button>
                ))}
              </div>
            </div>

            {/* Display media if available */}
            {data.data[currentIndex].media.length > 0 && (
              <div className="carousel max-w-[300px] ">
                {data.data[currentIndex].media.map((mediaItem) => (
                  <div
                    key={mediaItem._id}
                    className="mb-4 carousel-item w-full "
                  >
                    {mediaItem.type === "image" ? (
                      <img
                        src={mediaItem.url}
                        alt="Feed Media"
                        className="w-full h-auto object-contain rounded-lg"
                      />
                    ) : (
                      <video
                        src={mediaItem.url}
                        controls
                        className="w-full h-auto object-contain"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* text area */}
            <div className=" rounded-[12px] p-5 md:p-7 mb-[24px] bg-lightGrey/20 mt-10  mr-7  md:mr-14">
              <p className=" text-16px font-normal mb-3">
                {data.data[currentIndex].description}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-96 flex items-center justify-center">
          <p className="text-center">No Posts found</p>
        </div>
      )}
    </BaseDashboardNavigation>
  );
};

export default AllFeedsPage;
