import React, { useEffect, useState } from "react";
import { AiOutlineDollar } from "react-icons/ai";
import useGetListOfPlansManager from "../modules/admin/pricing/controller/getListOfPlansController";
import Loader from "./Loader";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import ModalManagement from "./ModalManagement";
import useDeletePlanManager from "../modules/admin/pricing/controller/deletePlanController";
import CustomButton from "./Button";
import ErrorManager from "./ErrorManager";
import EditPlanComponent from "../modules/admin/pricing/components/EditPlanComponent";

const PricingTable = () => {
  const { isError, data, error, isLoading } = useGetListOfPlansManager();
  const {
    deleteCaller,
    error: deleteError,
    isLoading: deleteIsLoading,
    isSuccess,
  } = useDeletePlanManager();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [planDetail, setPlanDetail] = useState(null);
  const handleDetailsClick = (index) => {
    setCurrentIndex(index);
    window.my_modal_6.showModal();
  };

  useEffect(() => {
    if (!isLoading) {
      setPlanDetail({
        name: data.data[currentIndex].name,
        price: data.data[currentIndex].price,
        profile_limit: data.data[currentIndex].profile_limit,
        discount: data.data[currentIndex].discount,
        planId: data.data[currentIndex].id,
      });
    }
  }, [currentIndex, isLoading]);
  const handleSubmission = async () => {
    // handle submission logic

    await deleteCaller(data.data[currentIndex].id);
  };

  if (isLoading || deleteIsLoading) {
    return <Loader />;
  }

  if (isError || deleteError) {
    return (
      <div>
        <ErrorManager errorMessage={error.message} />
        <ErrorManager errorMessage={error.deleteError} />
      </div>
    );
  }

  const style =
    "py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest";
  return (
    <table class="relative w-full lg:divide-gray-200 lg:divide-y scrollbar-hide">
      <thead class="hidden lg:table-header-group">
        <tr>
          <th class={style}>Plan</th>

          <th class={style}>Cost /mo</th>

          <th class={style}>Max Profiles</th>
          <th class={style}>Action</th>
        </tr>
      </thead>

      {/* this modal for removing plans */}
      <ModalManagement
        id="my_modal_5"
        children={
          <div className="bg-highlightBlue p-14">
            {data.data[currentIndex] ? (
              <p className=" mb-10 font-normal text-blackColor text-center">
                {`Are you sure you want remove ${data.data[currentIndex].name} from the list?`}
              </p>
            ) : (
              <p className="text-blackColor">User data not available.</p>
            )}

            {isSuccess && <p className="text-green-500">{data.message}</p>}
            {error && <p className="text-red-500">{error.message}</p>}
            <CustomButton
              textColor={"blackColor"}
              buttonText={"Yes, I am sure."}
              buttonColor={"offCoffee"}
              className={"w-full"}
              isLoading={isLoading}
              onClick={handleSubmission}
            />
          </div>
        }
      />

      {/* this modal for editing plans */}
      <ModalManagement
        id="my_modal_6"
        children={
          planDetail && (
            <EditPlanComponent
              name={planDetail.name}
              price={planDetail.price}
              discount={planDetail.discount}
              profile_limit={planDetail.profile_limit}
              planId={planDetail.planId}
            />
          )
        }
      />

      <tbody>
        {data.data.map((item, index) => {
          return (
            <tr
              key={index}
              className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
            >
              <td class="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                <div class="flex items-center mr-0 md:mr-8">
                  <div
                    className={`h-3 w-3 bg-brandOrange rounded-full mr-2`}
                  ></div>
                  {item.name}
                </div>
                <div class="mt-1 space-y-2 font-medium pl-11 lg:hidden">
                  <div class="flex items-center">
                    <AiOutlineDollar size={18} className="mr-2 text-black/40" />
                    {`${item.price} (${item.discount}%)`}
                  </div>

                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 mr-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    {item.profile_limit}
                  </div>

                  <div class="flex items-center pt-3 space-x-4">
                    <button
                      onClick={() => {
                        handleDetailsClick(index);
                      }}
                      class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Details
                    </button>
                    {item.name !== "Personal" && (
                      <button
                        onClick={() => {
                          setCurrentIndex(index);
                          window.my_modal_5.showModal();
                        }}
                      >
                        <IoMdRemoveCircleOutline color="blackColor" size={20} />
                      </button>
                    )}
                  </div>
                </div>
              </td>

              <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                <div class="flex items-center">
                  <AiOutlineDollar size={18} className="mr-2 text-black/40" />
                  {`${item.price} (${item.discount}%)`}
                </div>
              </td>

              <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 xl:table-cell whitespace-nowrap">
                <div class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-2 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  {item.profile_limit}
                </div>
              </td>

              <td class="hidden px-4 py-4 lg:table-cell whitespace-nowrap">
                <div class="flex items-center space-x-4">
                  <button
                    onClick={() => {
                      handleDetailsClick(index);
                    }}
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Details
                  </button>
                  {item.name !== "Personal" && (
                    <button
                      onClick={() => {
                        setCurrentIndex(index);
                        window.my_modal_5.showModal();
                      }}
                    >
                      <IoMdRemoveCircleOutline color="blackColor" size={20} />
                    </button>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PricingTable;
