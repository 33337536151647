import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import FeedTiles from "../../../../generalComponents/FeedTiles";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import useGetFeedsManager from "../controllers/getListOfFeedsController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import CustomButton from "../../../../generalComponents/Button";
import useFileUpload from "../../../fileupload/fileUploadController";
import useCreateFeedManager from "../controllers/createFeedController";
import useEditFeedManager from "../controllers/editFeedController";

const AdminAllFeedsPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [media, setMedia] = useState([]);
  const [mediaPicked, setMediaPicked] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [currentPostId, setCurrentPostId] = useState("");
  const [editPost, setEditPost] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [mainPicture, setMainPicture] = useState(null);
  const [mainPicturePicked, setMainPicturePicked] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showFeedbacks, setShowFeedbacks] = useState(false);

  const { isError, data, isSuccess, error, isLoading, refetch } =
    useGetFeedsManager(searchQuery);
  const {
    error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: fileLoading,
    data: uploadUrl,
  } = useFileUpload();
  const {
    postCaller,
    isLoading: creatingFeed,
    data: creationFeedback,
    isSuccess: feedCreated,
    error: feedError,
    isError: feedhasError,
  } = useCreateFeedManager();
  const {
    updateCaller,
    isLoading: updatingFeed,
    data: updateFeedback,
    isSuccess: updated,
    error: updateError,
    isError: updateHasError,
  } = useEditFeedManager(currentPostId);

  const [formData, setFormData] = useState({
    title: "",
    schedule: "",
    tags: "",
    description: "",
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        setSearchQuery(`?title=${searchTerm}`);
        refetch();
      } else {
        setSearchQuery(""); // Clear the search query
        refetch();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (editPost) {
      const selectedPost = data.data[currentIndex];

      setFormData({
        title: selectedPost.title,
        schedule: selectedPost.schedule,
        tags: selectedPost.tags.join(", "),
        description: selectedPost.description,
      });
    } else {
      setFormData({
        title: "",
        schedule: "",
        tags: "",
        description: "",
      });
    }
  }, [editPost, currentIndex, data]);

  const handleMainPictureUpload = () => {
    return new Promise(async (resolve, reject) => {
      setUploadingPhoto(true);
      try {
        const fileUrl = await uploadFile(mainPicture);
        console.log(`this is the url ${fileUrl}`);
        setUploadingPhoto(false);
        setMainPicturePicked(false);
        resolve(fileUrl); // Resolve the promise with the photo value
        return fileUrl;
      } catch (error) {
        console.error("Photo upload error:", error);
        setUploadingPhoto(true);
        reject(error); // Reject the promise with the error
      } finally {
        setUploadingPhoto(false);
      }
    });
  };

  const handleMediaUpload = () => {
    return new Promise(async (resolve, reject) => {
      setUploadingMedia(true);
      try {
        const uploadPromises = media.map(async (file) => {
          const fileUrl = await uploadFile(file);
          console.log(`Uploaded file URL: ${fileUrl}`);
          return fileUrl;
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        console.log(`Uploaded ${uploadedFiles.length} files in total`);

        setUploadingMedia(false);
        setMediaPicked(false);
        resolve(uploadedFiles); // Resolve the promise with the array of uploaded file URLs
        return uploadedFiles;
      } catch (error) {
        console.error("Media upload error:", error);
        setUploadingMedia(true);
        reject(error); // Reject the promise with the error
      } finally {
        setUploadingMedia(false);
      }
    });
  };

  const handleSubmit = async () => {
    setShowFeedbacks(true);
    const mainPictureUrl = mainPicturePicked
      ? await handleMainPictureUpload()
      : "";

    const galleryUrls = mediaPicked ? await handleMediaUpload() : [];
    console.log(`the uploaded gallery ${galleryUrls}`);
    // Dynamically determine media type based on URL

    let mediaArray = []; // Initialize an empty media array
    if (mediaPicked) {
      mediaArray = galleryUrls.map((url) => {
        const type =
          url.endsWith(".mp4") || url.endsWith(".mov") ? "video" : "image";
        return { url, type };
      });
    }

    const tagsArray = formData.tags
      .split(", ")
      .filter((tag) => tag.trim() !== "");

    if (editPost) {
      const details = {
        cover_image: mainPicturePicked
          ? mainPictureUrl
          : data.data[currentIndex].cover_image,
        title: formData.title,
        media: mediaPicked ? mediaArray : data.data[currentIndex].media,
        description: formData.description,
        tags: tagsArray,
        schedule: formData.schedule,
      };

      await updateCaller(details);
      setMedia("");
      setMainPicture("");
      setFormData({
        title: "",
        schedule: "",
        tags: "",
        description: "",
      });
    } else {
      const details = {
        cover_image: mainPictureUrl,
        title: formData.title,
        media: mediaArray,
        description: formData.description,
        tags: tagsArray,
        schedule: formData.schedule,
      };
      await postCaller(details);
      setMedia("");
      setMainPicture("");
      setFormData({
        title: "",
        schedule: "",
        tags: "",
        description: "",
      });
    }
  };

  const inputRef = useRef(null);
  const mainPictureInputRef = useRef(null);
  const handleMainPictureClick = () => {
    mainPictureInputRef.current.click();
  };
  const handleClick = () => {
    inputRef.current.click();
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM d, yyyy");
  };
  if (isLoading) {
    return <Loader />;
  }

  if (
    isSuccess &&
    data.data.length &&
    currentPostId !== data.data[currentIndex].id
  ) {
    setCurrentPostId(data.data[currentIndex].id);
  }

  if (isError) {
    return (
      <div>
        <ErrorManager errorMessage={error.message} />
        {/* <ErrorManager errorMessage={error.deleteError} /> */}
      </div>
    );
  }

  return (
    <BaseDashboardNavigation
      title={"Feeds"}
      value={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        console.log(searchTerm);
      }}
    >
      <div className="md:flex md:space-x-[20px] space-y-20 md:space-y-5 relative h-full w-full md:w-[98%]">
        {/* scroll feeds */}

        {data.data.length > 0 && (
          <div className="overflow-y-hidden md:overflow-y-auto  overflow-x-auto md:overflow-x-hidden flex flex-row md:flex-col md:gap-y-4 md:w-[25%] scrollbar-hide mb-[39px] relative md:h-[80vh]">
            {data.data.map((feed, index) => {
              return (
                <FeedTiles
                  isCurrent={index === currentIndex}
                  feedDetail={feed}
                  onClick={() => {
                    setCurrentIndex(index);
                    setShowFeedbacks(false);
                  }}
                />
              );
            })}
          </div>
        )}

        {/* feed details */}
        {data.data.length > 0 ? (
          <div className="w-full md:w-[40%] mb-[39px] md:h-[80vh]  overflow-y-auto scrollbar-hide  scroll-smooth">
            <div className="mb-5 mr-7  md:mr-0">
              <p className="text-30px font-bold text-blackColor">
                {data.data[currentIndex].title}
              </p>
              <div className="flex items-center justify-between mb-3">
                <p className=" text-16px font-normal ">
                  {formatDate(data.data[currentIndex].schedule)}
                </p>
                <button
                  onClick={() => {
                    setEditPost(!editPost);
                  }}
                  className="rounded-full py-1 px-5 bg-offCoffee hover:bg-brandOrange hover:scale-y-105 hover:shadow-xl"
                >
                  {!editPost ? "Edit Post" : "Cancel Edit"}
                </button>
              </div>
              <div className="flex space-x-1">
                {data.data[currentIndex].tags.map((tag) => (
                  <p className="bg-offCoffee rounded-[12px] font-medium py-0 px-4 text-blackColor">
                    {tag}
                  </p>
                ))}
              </div>
            </div>

            {/* Display media if available */}
            {data.data[currentIndex].media.length > 0 && (
              <div className="carousel w-full ">
                {data.data[currentIndex].media.map((mediaItem) => (
                  <div
                    key={mediaItem._id}
                    className="mb-4 carousel-item w-full "
                  >
                    {mediaItem.type === "image" ? (
                      <img
                        src={mediaItem.url}
                        alt="Feed Media"
                        className="w-full h-auto object-contain rounded-lg"
                      />
                    ) : (
                      <video
                        src={mediaItem.url}
                        controls
                        className="w-full h-auto object-contain"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* text area */}
            <div className=" rounded-[12px] p-5 md:p-7 mb-[24px] bg-lightGrey/20 mt-4 mr-7 ">
              <p className=" text-16px font-normal mb-3">
                {data.data[currentIndex].description}
              </p>
            </div>
          </div>
        ) : (
          <p className="md:w-[65%]">No Post Found</p>
        )}

        {/* create or edit container */}
        <div className="rounded-[20px] max-w-full md:w-[30%]  mr-7 p-5 bg-lightGrey/20 md:h-[80vh]  overflow-y-auto scrollbar-hide ">
          <p className="text-18px mb-4 font-bold">
            {editPost ? "Edit Feed" : "Create Feeds"}
          </p>
          <div className="flex items-center justify-start mb-10 w-full ">
            <input
              ref={mainPictureInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(event) => {
                const files = event.target.files;
                if (files && files.length > 0) {
                  setMainPicture(files[0]);
                  setSelectedFileName(files[0].name);
                  setMainPicturePicked(true);
                } else {
                  setMainPicture(null);
                  setMainPicturePicked(false);
                }
              }}
            />
            <div className="flex flex-col md:flex-row items-center justify-start w-full">
              <CustomButton
                isLoading={fileLoading && uploadingPhoto}
                loader={"uploading image"}
                onClick={handleMainPictureClick}
                className={"mr-4 text-[15px]"}
                buttonText={"Image"}
                textColor={"blackColor"}
              />
              <p className=" text-14px  text-black font-normal">
                {selectedFileName ? selectedFileName : "Cover Image"}
              </p>
            </div>
          </div>
          <InputWithFullBoarder
            label={"Title"}
            className={"mb-4"}
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />
          <InputWithFullBoarder
            label={"Schedule"}
            className={"mb-4"}
            type={"date"}
            value={formData.schedule}
            onChange={(e) =>
              setFormData({ ...formData, schedule: e.target.value })
            }
          />

          <div className="flex items-center justify-start mb-10 w-full ">
            <input
              ref={inputRef}
              type="file"
              multiple
              accept="image/png, image/jpeg, video/mp4, video/quicktime"
              style={{ display: "none" }}
              onChange={(e) => {
                const files = Array.from(e.target.files);
                if (files.length > 0) {
                  setMedia(files);
                  setMediaPicked(true);
                } else {
                  setMedia([]);
                  setMediaPicked(false);
                }
              }}
            />
            <div className="flex flex-col md:flex-row items-center justify-start w-full">
              <CustomButton
                isLoading={fileLoading && uploadingMedia}
                loader={"uploading media"}
                onClick={handleClick}
                className={"mr-4 text-[15px]"}
                buttonText={"Media"}
                textColor={"blackColor"}
              />
              <p className=" text-14px  text-black font-normal">
                {media.length > 0
                  ? `${media.length} files selected`
                  : "Upload Image/video"}
              </p>
            </div>
          </div>
          <InputWithFullBoarder
            label={"Enter your tags and separate with a comma (,)"}
            className={"mb-4"}
            value={formData.tags}
            onChange={(e) => setFormData({ ...formData, tags: e.target.value })}
          />
          <InputWithFullBoarder
            label={"Description"}
            isTextArea={true}
            className={"mb-4"}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />

          <CustomButton
            buttonText={editPost ? "Edit Feed" : "Create Feed"}
            textColor={"blackColor"}
            isLoading={updatingFeed || creatingFeed}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default AdminAllFeedsPage;
