import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import AllProfilesTable from "../../../../generalComponents/AllProfilesTable";
import useGetAllProfilesManager from "../controllers/getAllProfilesController";
import Loader from "../../../../generalComponents/Loader";
import useGetAnalytics from "../controllers/getAnalytics";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { Pagination } from "@mui/material";
import PaginationRounded from "../../../../generalComponents/Pagination";

const AllProfilesPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const { isError, isLoading, data, error, refetch } = useGetAllProfilesManager(
    currentPage,
    false,
    false,
    "",
    searchQuery
  );
  const handlePageChange = (page) => {
    if (page === currentPage) return; // No action needed if already on the current page

    if (page === data.data.pagination.nextPage) {
      // Move to the next page
      setCurrentPage(page);
      refetch();
    } else if (page === data.data.pagination.prevPage) {
      // Move to the previous page
      setCurrentPage(page);
      refetch();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        setSearchQuery(`&fullname=${searchTerm}`);
        refetch();
      } else {
        setSearchQuery(""); // Clear the search query
        refetch();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);
  const {
    data: analytics,
    error: analyticsError,
    isError: analyticsHasError,
    isLoading: loadingAnalytics,
  } = useGetAnalytics();
  if (isLoading || loadingAnalytics) {
    return <Loader />;
  }

  if (isError || analyticsHasError) {
    return (
      <div>
        {isError && <ErrorManager errorMessage={error.message} />}
        {analyticsHasError && (
          <ErrorManager errorMessage={analyticsError.message} />
        )}
      </div>
    );
  }
  return (
    <BaseDashboardNavigation
      title={"All Profiles"}
      value={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        console.log(searchTerm);
      }}
    >
      <div className="flex flex-col  h-[90vh]">
        {/* analytics */}
        <div className="flex flex-col md:flex-row justify-between w-[90%] text-white md:space-x-[13px] space-y-[13px] md:space-y-0 py-1 mb-[45px] md:mb-12 ">
          <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-offCoffee p-8 w-full  flex flex-col justify-center">
            <p className="text-30px font-semibold ">
              {analytics.data.totalProfiles}
            </p>
            <p className="text-16px font-semibold ">Total Profiles</p>
          </div>
          <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-brandOrange p-8  w-full flex flex-col justify-center">
            <p className="text-30px font-semibold ">
              {analytics.data.personalProfiles}
            </p>
            <p className="text-16px font-semibold ">Personal Profiles</p>
          </div>
          {/* <div className="h-[127px] rounded-[20px] bg-brandGreen p-8 w-full   flex flex-col justify-center">
            <p className="text-30px font-semibold ">
              {analytics.data.personalProfiles}
            </p>
            <p className="text-16px font-semibold ">Personal Profiles</p>
          </div> */}
          <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-brandGreen p-8 w-full   flex flex-col justify-center">
            <p className="text-30px font-semibold ">
              {analytics.data.forYouProfiles}
            </p>
            <p className="text-16px font-semibold ">Profile For Others</p>
          </div>
        </div>

        {/* profile tables */}
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {data.data.profiles.length ? (
            <AllProfilesTable profiles={data.data.profiles} />
          ) : (
            <p className="w-full text-center h-full"> No Profiles Found </p>
          )}
        </div>

        <div className="my-10 flex items-center justify-center">
          <PaginationRounded
            count={data?.data?.pagination?.pageTotal}
            defaultPage={data?.data?.pagination?.currentPage}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default AllProfilesPage;
