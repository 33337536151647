import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useAllUsersManager = (page, searchQuery) => {
  return useQuery(["allUsers", page, searchQuery], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/user/list?page=${page}&pageSize=10${searchQuery}`
        ),
      ];
      console.log(`i am checking this ${response.data.data.users}`);
      return response.data.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useAllUsersManager;
