import React, { useEffect, useState } from "react";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../../generalComponents/Button";
import useEditPlanManager from "../controller/editPlanController";
import { toast } from "react-toastify";

const EditPlanComponent = ({
  name,
  price,
  profile_limit,
  discount,
  planId,
}) => {
  const [planDetail, setPlanDetail] = useState({
    name: name,
    price: price,
    profile_limit: profile_limit,
    discount: discount,
    planId: planId,
  });
  useEffect(() => {
    setPlanDetail({
      name: name,
      price: price,
      profile_limit: profile_limit,
      discount: discount,
      planId: planId,
    });
  }, [name, price, profile_limit, discount, planId]);
  const { updateCaller, isLoading, data, isSuccess, error } =
    useEditPlanManager(planId);

  useEffect(() => {
    if (isSuccess) {
      toast.success(data.message);
    }

    if (error) {
      toast.error(error.message);
    }
  }, [isSuccess, data, error]);

  const handleSubmit = async () => {
    const details = {
      features: {
        allow_share: true,
      },
      active: true,
      name: planDetail.name,
      description: "Access to all features",
      price: planDetail.price,
      limit: -1,
      duration: 30,
      isActive: true,
      discount: planDetail.discount,
      profile_limit: planDetail.profile_limit,
    };
    await updateCaller(details);
  };
  return (
    <div className="rounded-[20px] max-w-full  p-5 bg-white py-10 ">
      <p className="text-18px mb-4 font-bold">Add Pricing</p>

      <InputWithFullBoarder
        label={"Plan Name"}
        className={"mb-4"}
        value={planDetail.name}
        onChange={(e) => setPlanDetail({ ...planDetail, name: e.target.value })}
      />
      <InputWithFullBoarder
        label={"Cost per month"}
        className={"mb-4"}
        value={planDetail.price}
        onChange={(e) =>
          setPlanDetail({ ...planDetail, price: parseInt(e.target.value) })
        }
      />

      <div className="flex items-center">
        <div className="flex w-full">
          <InputWithFullBoarder
            label={"Max Profiles"}
            type={"number"}
            className={"mb-4 w-[90%]"}
            value={planDetail.profile_limit}
            onChange={(e) =>
              setPlanDetail({
                ...planDetail,
                profile_limit: parseInt(e.target.value),
              })
            }
          />
          <InputWithFullBoarder
            label={"Discount"}
            className={"mb-4 w-[80%]"}
            type={"number"}
            value={planDetail.discount}
            onChange={(e) =>
              setPlanDetail({
                ...planDetail,
                discount: parseInt(e.target.value),
              })
            }
          />
        </div>
        <p className="text-16px font-bold mr-3">%</p>
      </div>

      <CustomButton
        buttonText={"Edit Plan"}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditPlanComponent;
