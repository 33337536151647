import React, { useEffect, useState } from "react";
import { AiOutlineDollar } from "react-icons/ai";
import Loader from "./Loader";
import ModalManagement from "./ModalManagement";
import ErrorManager from "./ErrorManager";
import useGetListOfMetricsManager from "../modules/admin/metrics/controllers/getListOfMetricsController";
import EditMetricsComponent from "../modules/admin/metrics/components/EditMetricsComponent";

const MeasurementMetricsTable = ({}) => {
  const { isError, data, error, isLoading } = useGetListOfMetricsManager();
  const [selectedSexIndex, setSelectedSexIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [metricsDetails, setMetricsDetails] = useState(null);
  const handleDetailsClick = (index) => {
    setCurrentIndex(index);
    window.my_modal_6.showModal();
  };

  useEffect(() => {
    if (!isLoading) {
      setMetricsDetails({
        name: data.data[selectedSexIndex].metric.measurements[currentIndex]
          .measurement.name,
        sex: data.data[selectedSexIndex].metric.sex,
        active:
          data.data[selectedSexIndex].metric.measurements[currentIndex].active,
        image:
          data.data[selectedSexIndex].metric.measurements[currentIndex]
            .measurement.image,

        planId:
          data.data[selectedSexIndex].metric.measurements[currentIndex]
            .measurement.id,
      });
    }
    console.log(metricsDetails);
  }, [currentIndex, isLoading, selectedSexIndex]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <div>
        <ErrorManager errorMessage={error.message} />
        {/* <ErrorManager errorMessage={error.deleteError} /> */}
      </div>
    );
  }

  if (!data) {
    return <p className="text-blackColor">no data</p>;
  }

  const style =
    "py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest";
  return (
    <div>
      <div className="flex md:justify-end md:mr-10 mb-10 mt-5">
        <div className="bg-white rounded-[10px] h-[32px] flex items-center border border-lightGrey">
          <p
            onClick={() => {
              setSelectedSexIndex(0);
            }}
            className={`text-[16px] font-bold ${
              selectedSexIndex === 0 ? "bg-offCoffee" : "bg-transparent"
            } py-1 px-2 rounded-lg ${
              selectedSexIndex === 0 ? "text-white" : "text-black/60"
            } duration-500 ease-in-out`}
          >
            Female
          </p>
          <p
            onClick={() => {
              setSelectedSexIndex(1);
            }}
            className={`text-[16px] font-bold ${
              selectedSexIndex === 1 ? "bg-offCoffee" : "bg-transparent"
            } py-1 px-2 rounded-lg ${
              selectedSexIndex === 1 ? "text-white" : "text-black/60"
            } duration-500 ease-in-out`}
          >
            Male
          </p>
        </div>
      </div>
      <table class="relative w-full lg:divide-gray-200 lg:divide-y scrollbar-hide">
        <thead class="hidden lg:table-header-group sticky top-0 z-10 bg-white">
          <tr>
            <th class={style}>Name</th>

            <th class={style}>Gender</th>

            <th class={style}>Illustration</th>
            <th class={style}>Action</th>
          </tr>
        </thead>
        {/* this modal for editing metrics */}
        <ModalManagement
          id="my_modal_6"
          children={
            metricsDetails && (
              <EditMetricsComponent
                name={metricsDetails.name}
                active={metricsDetails.active}
                image={metricsDetails.image}
                sex={metricsDetails.sex}
                planId={metricsDetails.planId}
              />
            )
          }
        />
        <tbody>
          {data.data[selectedSexIndex].metric.measurements.map(
            (item, index) => {
              return (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                >
                  <td class="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                    <div class="flex items-center mr-0 md:mr-8">
                      <div
                        className={`h-3 w-3 bg-brandOrange rounded-full mr-2`}
                      ></div>
                      {item.measurement.name}
                    </div>
                    <div class="mt-1 space-y-2 font-medium pl-11 lg:hidden">
                      <div class="flex items-center">
                        <AiOutlineDollar
                          size={18}
                          className="mr-2 text-black/40"
                        />
                        {data.data[selectedSexIndex].metric.sex}
                      </div>

                      <div class="flex items-center">
                        <img
                          className="h-20 w-auto object-contain ml-7"
                          src={item.measurement.image}
                          alt=""
                        />
                      </div>
                      <button
                        onClick={() => {
                          handleDetailsClick(index);
                        }}
                        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Details
                      </button>
                    </div>
                  </td>

                  <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                    <div class="flex items-center">
                      <AiOutlineDollar
                        size={18}
                        className="mr-2 text-black/40"
                      />
                      {data.data[selectedSexIndex].metric.sex}
                    </div>
                  </td>

                  <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 xl:table-cell whitespace-nowrap">
                    <div class="flex items-center">
                      <img
                        className="h-20 w-auto object-contain ml-7"
                        src={item.measurement.image}
                        alt=""
                      />
                    </div>
                  </td>
                  <td class="hidden px-4 py-4 lg:table-cell whitespace-nowrap">
                    <div class="flex items-center space-x-4">
                      <button
                        onClick={() => {
                          handleDetailsClick(index);
                        }}
                        class="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Edit
                      </button>
                    </div>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MeasurementMetricsTable;
