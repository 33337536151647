import React from "react";
import { homeBanner } from "../../../assets/images";

const HomeHero = ({ id }) => {
  return (
    <div id={id} class="bg-white">
      <section class="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid items-center mx-auto grid-cols-1 gap-12 lg:grid-cols-5">
            <div className="md:col-span-3 order-2 md:order-none">
              <h1 class="mt-4 text-[50px] font-bold text-black lg:mt-8 sm:text-[70px] xl:text-[70px] leading-tight">
                Elevate Your Fashion Profile Management
              </h1>
              <p className="mt-4 text-[20px] leading-10 text-black lg:mt-8 sm:text-xl md:w-[85%]">
                Seamlessly create, manage, and share fashion profiles with ease,
                whether you're a busy tailor or a style-savvy individual. Say
                goodbye to the hassles of traditional measurement tracking and
                embrace a streamlined approach to fashion excellence.
              </p>

              <div>
                <a
                  href="https://apps.apple.com/ng/app/raizon/id6468570935"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:scale-110 hover:shadow-lg inline-flex items-center px-6 py-4 mt-8 text-black font-bold transition-all duration-200  rounded-[25px] lg:mt-16 border border-blackColor mr-0 md:mr-5"
                >
                  <svg
                    className="mr-[12px]"
                    width="23"
                    height="29"
                    viewBox="0 0 30 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.7961 18.6503C24.7803 15.7586 26.0883 13.576 28.7357 11.9686C27.2544 9.8491 25.0167 8.68297 22.062 8.45447C19.2648 8.23385 16.2077 10.0855 15.0888 10.0855C13.9069 10.0855 11.1965 8.53326 9.06905 8.53326C4.67241 8.60417 0 12.0395 0 19.0285C0 21.0928 0.378205 23.2255 1.13462 25.4264C2.14316 28.3181 5.78339 35.4095 9.5812 35.2913C11.5668 35.244 12.9693 33.8809 15.5537 33.8809C18.0593 33.8809 19.3594 35.2913 21.5735 35.2913C25.4028 35.2361 28.6963 28.7909 29.6576 25.8913C24.5203 23.4724 24.7961 18.8 24.7961 18.6503ZM20.3364 5.71248C22.4875 3.15959 22.2905 0.835204 22.2274 0C20.3285 0.11031 18.1302 1.2922 16.8774 2.74987C15.4985 4.30997 14.687 6.24039 14.8603 8.41507C16.9168 8.57266 18.7921 7.51683 20.3364 5.71248Z"
                      fill="black"
                    />
                  </svg>
                  Get on Apple
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.raizonfashion.raizon"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:scale-110 hover:shadow-lg inline-flex items-center px-6 py-4 mt-8 font-bold text-whiteColor transition-all duration-200 bg-blackColor rounded-[25px] lg:mt-16 hover:bg-black focus:bg-black"
                >
                  <svg
                    className="mr-[12px]"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.3712 11.5194H27.2174V11.4599H14.3249V17.1899H22.4207C21.2396 20.5255 18.0659 22.9199 14.3249 22.9199C9.57836 22.9199 5.72997 19.0715 5.72997 14.3249C5.72997 9.57836 9.57836 5.72997 14.3249 5.72997C16.5159 5.72997 18.5092 6.55652 20.027 7.90664L24.0788 3.85484C21.5203 1.47045 18.0981 0 14.3249 0C6.41398 0 0 6.41398 0 14.3249C0 22.2359 6.41398 28.6498 14.3249 28.6498C22.2359 28.6498 28.6498 22.2359 28.6498 14.3249C28.6498 13.3644 28.551 12.4269 28.3712 11.5194Z"
                      fill="#FFC107"
                    />
                    <path
                      d="M1.65167 7.65739L6.35813 11.109C7.63161 7.95606 10.7158 5.72997 14.3249 5.72997C16.5159 5.72997 18.5092 6.55652 20.027 7.90664L24.0788 3.85484C21.5203 1.47045 18.0981 0 14.3249 0C8.82273 0 4.0511 3.10636 1.65167 7.65739Z"
                      fill="#FF3D00"
                    />
                    <path
                      d="M14.3249 28.6499C18.0251 28.6499 21.3871 27.2339 23.9291 24.9311L19.4955 21.1795C18.0573 22.2689 16.2695 22.9199 14.3249 22.9199C10.599 22.9199 7.43536 20.5441 6.24353 17.2286L1.57217 20.8278C3.94295 25.4669 8.75756 28.6499 14.3249 28.6499Z"
                      fill="#4CAF50"
                    />
                  </svg>
                  Get on Android
                </a>
              </div>
            </div>

            <div className="w-full  md:col-span-2 order-1 md:order-none">
              <img
                className="w-full md:ml-auto"
                src={homeBanner}
                alt="pattern design"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeHero;
