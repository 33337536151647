import React, { useEffect, useRef, useState } from "react";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../../generalComponents/Button";

import useFileUpload from "../../../fileupload/fileUploadController";
import useEditMetricsManager from "../controllers/editMetricsController";
import { toast } from "react-toastify";

const EditMetricsComponent = ({ name, sex, active, image, planId }) => {
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [mainPicture, setMainPicture] = useState(null);
  const [mainPicturePicked, setMainPicturePicked] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [measurementDetails, setMeasurementDetails] = useState({
    image: image,
    name: name,
    sex: sex,
    active: active,
  });
  useEffect(() => {
    setMeasurementDetails({
      image: image,
      name: name,
      sex: sex,
      active: active,
    });
  }, [name, sex, active, image]);

  const { updateCaller, isLoading, data, isSuccess, error } =
    useEditMetricsManager(planId);

  useEffect(() => {
    if (isSuccess) {
      toast.success(data.message);
    }
    if (error) {
      toast.success(error.message);
    }
  }, [isSuccess, data, error]);

  const {
    error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: fileLoading,
    data: uploadUrl,
  } = useFileUpload();

  const handleMainPictureUpload = () => {
    return new Promise(async (resolve, reject) => {
      setUploadingPhoto(true);
      try {
        const fileUrl = await uploadFile(mainPicture);
        console.log(`this is the url ${fileUrl}`);
        setUploadingPhoto(false);
        setMainPicturePicked(false);
        resolve(fileUrl); // Resolve the promise with the photo value
        return fileUrl;
      } catch (error) {
        console.error("Photo upload error:", error);
        setUploadingPhoto(true);
        reject(error); // Reject the promise with the error
      } finally {
        setUploadingPhoto(false);
      }
    });
  };
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleSubmit = async () => {
    if (!name) {
      toast.error("Please enter the name");
      return;
    }
    const mainPictureUrl = mainPicturePicked
      ? await handleMainPictureUpload()
      : "";

    const details = {
      name: measurementDetails.name,
      active: measurementDetails.active,
      image: mainPictureUrl || image,
    };
    await updateCaller(details);
    setSelectedFileName("");
  };

  return (
    <div className="flex flex-col md:flex-row md:space-x-[20px] space-y-20 md:space-y-5 relative h-full max-w-full ">
      {/* create or edit container */}
      <div className="rounded-[20px] max-w-full p-5 py-10 bg-white order-1 md:order-none ">
        <p className="text-18px mb-10 font-bold">Edit Measurements</p>
        <div className="flex items-center justify-start mb-10 w-full ">
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event) => {
              const files = event.target.files;
              if (files && files.length > 0) {
                setMainPicture(files[0]);
                setSelectedFileName(files[0].name);
                setMainPicturePicked(true);
              } else {
                setMainPicture(null);
                setMainPicturePicked(false);
              }
            }}
          />
          <CustomButton
            isLoading={fileLoading}
            loader={"uploading image"}
            onClick={handleClick}
            className={"mr-4"}
            buttonText={"Upload Image"}
          />

          <p className=" text-13px  font-normal">
            {selectedFileName ?? "Upload image"}
          </p>
        </div>

        <InputWithFullBoarder
          label={"Measurement Name"}
          className={"mb-4"}
          value={measurementDetails.name}
          onChange={(e) =>
            setMeasurementDetails({
              ...measurementDetails,
              name: e.target.value,
            })
          }
        />

        <select
          className={`border border-lightGrey w-full mb-20 bg-lightGrey/30 p-2 rounded-md outline-none focus:outline-none `}
          value={measurementDetails.active === true ? "true" : "false"} // Set strings representing boolean values
          onChange={(e) => {
            setMeasurementDetails({
              ...measurementDetails,
              active: e.target.value === "true", // Convert the string to a boolean
            });
          }}
        >
          <option value="true">Active</option>
          <option value="false">InActive</option>
        </select>

        <CustomButton
          buttonText={"Update Metrics"}
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default EditMetricsComponent;
