import React from "react";
import { authentication, logo, topPattern } from "../../../assets/images";
import CustomButton from "../../../generalComponents/Button";
import { Link } from "react-router-dom";

const AuthenticationBase = ({
  title,
  subtitle,
  inputFields,
  buttonText,
  onClick,
  afterElements,
  isLoading,
}) => {
  return (
    <div className="h-full md:h-[98vh] relative text-blackColor">
      <img
        className=" w-full object-cover top-0 relative "
        src={topPattern}
        alt=""
        relative
      />
      <div className="flex flex-col md:flex-row items-center justify-around p-5 md:p-40 bg-deepGrey h-full">
        <img
          className="max-h-[681px] py-20 2xl:py-0 object-contain"
          src={authentication}
          alt="pattern design"
        />

        <div className="md:p-20">
          <Link to={"/"}>
            <img
              className="object-contain w-[10rem] mb-7 mt-14 mx-auto"
              src={logo}
              alt="Raizon logo"
            />
            {/* <p className="text-[35px] text-blackColor font-playfair-sc text-center mb-7 ">
              RAIZON
            </p> */}
          </Link>
          <div className="bg-white rounded-[35px] p-10 flex flex-col justify-center">
            <div className="mb-[64px] text-center">
              <p className="text-30px leading-8 font-bold mb-1">{title}</p>
              <p className="text-16px leading-5 font-semibold">{subtitle}</p>
            </div>
            {inputFields}
            {/* <button
              onClick={onClick}
              className="bg-offCoffee rounded-[20px] w-[60%] mx-auto py-1.5 px-10 mt-5"
            >
              {buttonText}
            </button> */}

            <CustomButton
              buttonText={buttonText}
              onClick={onClick}
              className="mt-[27px] mb-[31px]"
              isLoading={isLoading}
            />
          </div>
          {afterElements}
        </div>
      </div>
      <img
        className=" w-full object-cover bottom-0 relative"
        src={topPattern}
        alt=""
      />
    </div>
  );
};

export default AuthenticationBase;
