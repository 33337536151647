import React from "react";
import { Puff } from "react-loader-spinner";

const Loader = ({ isSmall = false }) => {
  const containerStyles = isSmall
    ? { display: "flex", justifyContent: "center", alignItems: "center" }
    : {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      };
  return (
    <div style={containerStyles}>
      <Puff
        height="80"
        width="80"
        radius={1}
        color="#D58A1F"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
