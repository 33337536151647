import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetAllProfilesManager = (
  page,
  isDashboard,
  forUser = false,
  userId,
  searchQuery = ""
) => {
  return useQuery(["profilesList", page, searchQuery], async () => {
    console.log(userId);
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/user/profiles?page=${page}&pageSize=${isDashboard ? "5" : "10"}${
            forUser ? `&user=${userId}` : ""
          }${searchQuery}`
        ),
      ];
      console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data}`);
    }
  });
};

export default useGetAllProfilesManager;
