import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../assets/images";

const Header = ({
  clickHome,
  clickFeatures,
  clickSolutions,

  clickDownload,
}) => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);

  const isMobile = () => {
    setMobile(!mobile);
  };
  return (
    <div className="sticky top-0 z-50 bg-white">
      <header class="bg-opacity-30 pt-7 mx-auto max-w-7xl">
        <div class="px-4 mx-auto sm:px-6 lg:px-8">
          <div class="flex items-center justify-between h-16 lg:h-20">
            <div class="flex-shrink-0">
              <a href="/" title="" class="flex">
                <img class="w-auto h-[52px]" src={logo} alt="raizon logo" />
                {/* <p className="w-auto text-logo font-playfair-sc flex text-blackColor">
                  {" "}
                  <button onClick={clickHome}>RAIZON</button>
                </p> */}
              </a>
            </div>

            <div className="flex w-[60%] md:w-auto justify-end">
              <button
                onClick={isMobile}
                type="button"
                class="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100"
              >
                {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
                <svg
                  class="block w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 8h16M4 16h16"
                  ></path>
                </svg>

                {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
                <svg
                  class="hidden w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            {/* mobile view menu logic starts here */}
            {/* <div
              onClick={isMobile}
              className="fixed md:hidden right-0 top-5 mr-8"
            >
              {mobile ? (
                <AiOutlineClose size={20} color="white" />
              ) : (
                <AiOutlineMenu size={20} color="white" />
              )}
            </div> */}
            <ul
              onClick={isMobile}
              className={`fixed md:hidden ${
                mobile ? "left-0" : "left-[-100%]"
              } top-0 w-[60%] h-full bg-white text-blackColor ease-in-out duration-500`}
            >
              <div className="">
                <img
                  className="object-contain w-[8rem] mt-16 ml-5"
                  src={logo}
                  alt="avowal logo"
                />
                {/* <p className="w-auto text-logo font-playfair-sc flex mt-16 ml-5 text-blackColor">
                  {" "}
                  <button onClick={clickHome}>RAIZON</button>
                </p> */}
              </div>

              <li onClick={clickFeatures} className="p-4 ml-5">
                Features
              </li>
              <li onClick={clickSolutions} className="p-4 ml-5">
                Solutions
              </li>
              <li className="p-4 ml-5">
                <Link to={"/support"}>Contact</Link>
              </li>
              <li onClick={clickDownload} className="p-4 ml-5">
                Download App
              </li>

              <li className="p-4 ml-5 ">
                {/* <div className="py-3 px-5 rounded w-[70%] bg-mustardColor ">
                  {" "}
                  {tokenExists ? (
                    <Link to="/dashboard">Dashboard</Link>
                  ) : (
                    <Link to="/login">Login</Link>
                  )}
                </div> */}
                <div className="">
                  <button
                    onClick={() => navigate("/login")}
                    class=" lg:inline-flex items-center w-[80%] justify-center px-5 py-1.5 text-[16px] transition-all duration-200 hover:bg-brandOrange hover:text-black focus:text-black font-medium text-blackColor border border-blackColor hover:border-offCoffee rounded-[25px] mr-3 mb-3"
                  >
                    Sign in
                  </button>
                  <button
                    onClick={() => navigate("/create-account")}
                    class=" lg:inline-flex items-center justify-center px-5 w-[80%] py-1.5 text-[16px] transition-all duration-200 hover:bg-brandOrange hover:text-black focus:text-black  font-medium text-white bg-offCoffee rounded-[25px]"
                  >
                    Sign up
                  </button>
                </div>
              </li>
            </ul>

            <div class="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10">
              <button
                onClick={clickFeatures}
                title=""
                class="text-base text-black transition-all duration-200 hover:text-opacity-80"
              >
                Features
              </button>

              <button
                onClick={clickSolutions}
                title=""
                class="text-base text-black transition-all duration-200 hover:text-opacity-80"
              >
                Solutions{" "}
              </button>

              <button
                title=""
                class="text-base text-black transition-all duration-200 hover:text-opacity-80"
              >
                <Link to={"/support"}>Contact</Link>
              </button>

              <button
                onClick={clickDownload}
                title=""
                class="text-base text-black transition-all duration-200 hover:text-opacity-80"
              >
                {" "}
                Download App{" "}
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/login")}
                class="hidden lg:inline-flex items-center justify-center px-5 py-1.5 text-[20px] transition-all duration-200 hover:bg-brandOrange hover:text-black focus:text-black font-medium text-blackColor border border-blackColor rounded-[25px] mr-3 hover:border-offCoffee"
              >
                Sign in
              </button>
              <button
                onClick={() => navigate("/create-account")}
                class="hidden lg:inline-flex items-center justify-center px-5 py-1.5 text-[20px] transition-all duration-200 hover:bg-brandOrange hover:text-black focus:text-black  font-medium text-white bg-offCoffee rounded-[25px]"
              >
                Create an account
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
