import React from "react";
import useGetAllProfilesManager from "../modules/admin/profiles/controllers/getAllProfilesController";
import GlobalVariables from "../constants/GlobalVariables";
import { formatDistanceToNow } from "date-fns";
import Loader from "./Loader";
import ErrorManager from "./ErrorManager";
import { useNavigate } from "react-router-dom";

const FewDataTable = () => {
  const navigate = useNavigate();
  function formatDateAgo(date) {
    const options = { addSuffix: true };
    return formatDistanceToNow(new Date(date), options);
  }

  const { isError, isLoading, data, error } = useGetAllProfilesManager(1, true);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <div>{isError && <ErrorManager errorMessage={error.message} />}</div>
    );
  }

  const style =
    "py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest";
  return (
    <table class="relative w-full lg:divide-gray-200 lg:divide-y scrollbar-hide">
      <thead class="hidden lg:table-header-group">
        <tr>
          <th class={style}>Created By</th>

          <th class={style}>Profile Name</th>

          <th class={style}>Creation Date</th>
          <th class={style}>Action</th>
          {/* <th class="relative py-3.5 pl-4 pr-4 md:pr-0">
              <span class="sr-only"> Actions </span>
            </th> */}
        </tr>
      </thead>

      <tbody>
        {data.data.profiles.map((item, index) => {
          return (
            <tr className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}>
              <td class="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                <div class="flex items-center mr-0 md:mr-8">
                  <img
                    class="flex-shrink-0 object-cover w-8 h-8 mr-3 rounded-full"
                    src={
                      item.creator.profile_picture
                        ? item.creator.profile_picture
                        : GlobalVariables.defaultProfilePicture
                    }
                    alt=""
                  />
                  {item.creator.fullname}
                </div>
                <div class="mt-1 space-y-2 font-medium pl-11 lg:hidden">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 mr-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    {item.fullname}
                  </div>

                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 mr-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    {formatDateAgo(item.createdAt)}
                  </div>

                  <div class="flex items-center pt-3 space-x-4">
                    <button
                      onClick={() =>
                        navigate(`/admin/profile-details/${item.id}`)
                      }
                      class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-brandOrange"
                    >
                      Details
                    </button>
                  </div>
                </div>
              </td>

              <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                <div class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-2 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  {item.fullname}
                </div>
              </td>

              <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 xl:table-cell whitespace-nowrap">
                <div class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-2 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  {formatDateAgo(item.createdAt)}
                </div>
              </td>

              <td class="hidden px-4 py-4 lg:table-cell whitespace-nowrap">
                <div class="flex items-center space-x-4">
                  <button
                    onClick={() =>
                      navigate(`/admin/profile-details/${item.id}`)
                    }
                    class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-brandOrange"
                  >
                    Details
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default FewDataTable;
