import React from "react";

const BroadcastTile = ({ broadcast, onClick, isCurrent }) => {
  return (
    <div
      onClick={onClick}
      className={`max-h-[283px] hover:max-h-[290px] w-[233px] relative hover:w-[240px] hover:shadow-lg duration-300 shadow-black mb-4 hover:mb-2 rounded-[20px] bg-white hover:bg-offCoffee/10 ${
        isCurrent ? "border-brandOrange border-2 " : "border-lightGrey "
      }  border hover:border-transparent px-3 py-7 mr-5 duration-300`}
    >
      <p className="text-[20px] font-bold mb-4 relative">{broadcast.title}</p>
      <p className="text-[14px] overflow-hidden relative w-[220px] line-clamp-4">
        {broadcast.body}
      </p>
    </div>
  );
};

export default BroadcastTile;
