import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useGetSingleProfileManager from "../controllers/getSingleProfileController";
import MeasurementProfileDetails from "../../../../generalComponents/MeasurementProfileDetails";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import Loader from "../../../../generalComponents/Loader";
import { formatDistanceToNow } from "date-fns";
import HeaderWithOnlyLogin from "../../../../generalComponents/HeaderWithOnlyLogin";
import UserInformationBoxSharingView from "../../../../generalComponents/UserInformationBoxForSharing&View";

const PublicProfileDetailsPageView = () => {
  function formatDateAgo(date) {
    const options = { addSuffix: true };
    return formatDistanceToNow(new Date(date), options);
  }
  const currentUrl = window.location.href;

  // Parse the URL to get query parameters
  const url = new URL(currentUrl);

  // Get the values of the "profile" and "email" query parameters
  const urlProfile = url.searchParams.get("profile");
  const urlEmail = url.searchParams.get("email");

  const {
    isLoading,
    data,
    isError,
    error,
    isSuccess: profileLoaded,
  } = useGetSingleProfileManager(urlProfile);
  const navigate = useNavigate();

  const [currentMetricIndex, setCurrentMetricsIndex] = useState(0);

  const [historyMode, setHistoryMode] = useState(false);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <div>{isError && <ErrorManager errorMessage={error.message} />}</div>
    );
  }
  return (
    <div>
      <HeaderWithOnlyLogin />
      {
        <div className="md:flex md:space-x-[30px] space-y-20 md:space-y-5 relative h-full w-[98%] md:w-[77%] my-20 mr-7 text-blackColor mx-auto">
          {/* profiles details */}
          <div className="flex flex-col md:h-[80vh] md:w-[50%] md:overflow-y-scroll scrollbar-hide md:mr-7 mx-3 md:mx-0  relative mr-0 order-3 md:order-none">
            {/* user profile details sections */}

            <UserInformationBoxSharingView
              userDetails={data.data}
              isPersonal={false}
              editorsEmail={urlEmail}
              // inEditMode={editMode}
              // inHistoryMode={historyMode}
              // onHistoryClick={() => setHistoryMode(!historyMode)}
              // onClick={() => setEditMode(!editMode)}
            />
            {/* measurement Image */}
            <div className="  md:hidden rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 h-[400px] md:h-[80vh] w-full md:w-[26%] mr-7 relative md:mr-0 ">
              <img
                className="object-contain h-full w-full"
                src={data.data.measurement[currentMetricIndex].image}
                alt=""
              />
            </div>

            {/* profile details */}
            <MeasurementProfileDetails
              unitName={data.data.unit}
              isCM={data.data.unit === "inch"}
              measurements={data.data.measurement}
              onUpdateIndex={(newIndex) => setCurrentMetricsIndex(newIndex)}
            />
          </div>

          {/* measurement Image */}
          <div className=" hidden md:flex rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 h-[400px] md:h-[80vh] w-full md:w-[26%] mr-7 relative md:mr-0 ">
            <img
              className="object-contain h-full w-full"
              src={data.data.measurement[currentMetricIndex].image}
              alt=""
            />
          </div>
        </div>
      }
      <footer />
    </div>
  );
};

export default PublicProfileDetailsPageView;
