import React, { useEffect, useState } from "react";
import GlobalVariables from "../constants/GlobalVariables";
import { format } from "date-fns";
import { AiOutlineShareAlt } from "react-icons/ai";
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EmailShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailIcon,
  WhatsappIcon,
  TwitterShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { useLocation, useParams } from "react-router-dom";

import { FaCopy } from "react-icons/fa";
import CustomButton from "./Button";
import InputWithFullBoarder from "./InputWithFullBoarder";
import useShareProfileManager from "../modules/measurements/controllers/shareProfileController";
import useGetUserDetailsManager from "../modules/settings/controllers/get_UserDetails_controller";

const UserInformationBoxSharingView = ({
  userDetails,
  isPersonal,
  onClick,
  editorsEmail,
  onHistoryClick,
  inHistoryMode,
  inEditMode,
}) => {
  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return format(date, "MMMM d, yyyy");
  // };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM d, yyyy hh:mma");
  };
  const location = useLocation();

  // Check if the current pathname is "/share"

  const [editable, setEditable] = useState(false);
  const tokenExists = localStorage.getItem("token") !== null;
  const [email, setEmail] = useState("");

  const handleCopyPost = () => {
    // Copy the URL to the clipboard

    navigator.clipboard.writeText(
      `https://raizonfashion.com/view/?profile=${userDetails.id}`
    );
  };

  const { postCaller, error, isLoading, data, isSuccess } =
    useShareProfileManager(userDetails.id);
  const {
    data: userInfo,
    isError: userInfoHasError,
    error: userInfoError,
    isLoading: userInfoLoading,
    isSuccess: userInfoLoaded,
  } = useGetUserDetailsManager(location.pathname === "/share/" && tokenExists);

  const handleSubmit = async () => {
    const details = {
      emails: [
        {
          email: email,
          canEdit: true,
        },
      ],
    };
    await postCaller(details);
    setEmail("");
  };
  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
    if (isSuccess && data) {
      toast.success(data.message);
    }
  }, [error, isSuccess, data]);
  return (
    <div className=" rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 flex flex-col md:flex-row md:items-end justify-between border border-lightGrey">
      <div className=" flex items-center">
        {/* for sharing this post */}
        <dialog
          id="my_modal_6"
          className="modal modal-bottom sm:modal-middle mx-auto"
        >
          <form
            method="dialog"
            className="modal-box w-full md:w-96 bg-whiteColor"
          >
            <h3 className="font-bold text-lg">Share This Profile</h3>
            {location.pathname !== "/share/" && (
              <div
                className={`flex items-center justify-between ${
                  editable ? `mb-7` : `mb-0`
                }`}
              >
                <p className="py-4 mr-2">
                  {editable
                    ? "Turn off permission to edit"
                    : "Turn on permission to edit"}
                </p>

                <Switch
                  id="editable"
                  checked={editable}
                  onChange={() => setEditable(!editable)}
                  onColor="#EFC5A7"
                  offColor="#101010"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checkedHandleIcon={
                    <div
                      style={{ size: "7" }}
                      className="dot dot-on bg-brandOrange"
                    />
                  }
                  uncheckedHandleIcon={<div className="dot dot-off bg-white" />}
                />
              </div>
            )}
            {editable && (
              <InputWithFullBoarder
                label={"Enter Email address"}
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
            <div className="space-x-0 md:space-x-3 mx-auto max-w-max flex justify-center">
              <CustomButton
                onClick={editable ? handleSubmit : handleCopyPost}
                isLoading={isLoading}
                className={editable ? "mt-0" : "mt-10"}
                buttonText={
                  editable ? "Send Profile Link" : "Copy Profile Link"
                }
              />
            </div>
            <div className="modal-action">
              {/* if there is a button in form, it will close the modal */}
              <CustomButton buttonText={"Close"} />
            </div>
          </form>
        </dialog>

        <div className="toast toast-start toast-middle">
          {error && (
            <div className="alert alert-error ">
              <span>{error.message}</span>
            </div>
          )}
          {isSuccess && (
            <div className="alert alert-success">
              <span>{data.message}</span>
            </div>
          )}
        </div>

        {isPersonal && (
          <div>
            <img
              className=" h-[75px] md:h-[95px]  w-[75px] md:w-[95px] rounded-full object-cover  mr-8"
              src={
                userDetails.profile_picture
                  ? userDetails.profile_picture
                  : GlobalVariables.defaultProfilePicture
              }
              alt=""
            />
          </div>
        )}
        <div>
          <div className="flex flex-col 2xl:flex-row items-start 2xl:items-center justify-start">
            <p className="text-[23px] 2xl:text-[30px] font-semibold mr-3 order-2 2xl:order-none leading-tight mb-2 mt-1.5">
              {userDetails.fullname}
            </p>
            {location.pathname === "/share/" && (
              <button
                onClick={() => {
                  window.my_modal_6.showModal();
                }}
                className="rounded-full py-1 px-4 bg-offCoffee flex items-center order-1 2xl:order-none"
              >
                <p className="text-[16px] font-normal mr-1">Share</p>
                <AiOutlineShareAlt size={20} />
              </button>
            )}
          </div>
          <p className="text-[16px] font-normal ">{userDetails.email}</p>
          <p className="text-[13px] font-normal ">
            {`Updated ${formatDate(userDetails.updatedAt)}`}
          </p>
        </div>
      </div>

      <div className="flex flex-col ">
        {/* {location.pathname === "/share/" && !inEditMode && (
          <button
            onClick={onHistoryClick}
            className="bg-offCoffee rounded-[20px] hover:bg-brandOrange hover:shadow-xl hover:scale-y-105 duration-300 mb-3 py-1.5 px-5 md:px-6 mt-2 md:mt-0 max-h-10 "
          >
            {inHistoryMode ? "Return" : "History"}
          </button>
        )} */}
        {location.pathname === "/share/" &&
          userInfo &&
          userInfo.data &&
          userInfo.data.user &&
          userInfo.data.user.email === editorsEmail &&
          !inHistoryMode && (
            <button
              onClick={onClick}
              className="bg-offCoffee hover:bg-brandOrange hover:shadow-xl hover:scale-y-105 duration-300 rounded-[20px] py-1.5 px-5 md:px-6 mt-2 md:mt-0 max-h-10  "
            >
              {inEditMode ? "Cancel Edit" : "Edit"}
            </button>
          )}
      </div>
    </div>
  );
};

export default UserInformationBoxSharingView;
