import React from "react";

import { homeBanner, topPattern } from "../../../assets/images";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import HomeHero from "../components/HomeHero";
import Benefits from "../components/Benefits";
import HowItWork from "../components/HowItWork";
import Testimonial from "../components/Testimonial";
import Logos from "../components/Logos";
import CTASection from "../components/CTASection";
import Features from "../components/Features";

const HomePage = () => {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <HeaderFooter
      clickHome={() => handleScroll("home")}
      clickDownload={() => handleScroll("download")}
      clickFeatures={() => handleScroll("features")}
      clickSolutions={() => handleScroll("hiw")}
      children={
        <div>
          <HomeHero id="home" />
          {/* <Benefits /> */}
          <Features id="features" />
          <HowItWork id="hiw" />
          {/* <Testimonial /> */}
          {/* <Logos /> */}
          <CTASection id="download" />
          <img className="h-3 w-full object-cover " src={topPattern} alt="" />
        </div>
      }
    />
  );
};

export default HomePage;
