import React from "react";
import useAdoptProfileManager from "../modules/settings/controllers/adoptProfileController";
import GlobalVariables from "../constants/GlobalVariables";
import { format } from "date-fns";
import CustomButton from "./Button";

const UserInformationBoxWithBack = ({ onClick, profiles }) => {
  const { updateCaller, isLoading, isSuccess, data, error } =
    useAdoptProfileManager();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM d, yyyy hh:mma");
  };

  const handleSubmit = async () => {
    await updateCaller(profiles.id);
  };

  return (
    <div>
      {error && <p className="text-red-500">{error.message}</p>}
      {isSuccess && <p className="text-green-500">{data.message}</p>}
      <div className=" rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 flex flex-col md:flex-row md:items-end justify-between border border-lightGrey">
        <div className=" flex items-center">
          <div>
            <img
              className=" h-[75px] md:h-[95px]  w-[75px] md:w-[95px] rounded-full object-cover  mr-8"
              src={
                profiles.creator.profile_picture
                  ? profiles.creator.profile_picture
                  : GlobalVariables.defaultProfilePicture
              }
              alt=""
            />
          </div>
          <div>
            <p className="text-[16px] font-normal leading-tight">Created by</p>
            <p className="text-[30px] font-semibold leading-tight">
              {profiles.creator.fullname}
            </p>
            <p className="text-[16px] font-normal ">
              {`Updated ${formatDate(profiles.updatedAt)}`}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <CustomButton
            onClick={handleSubmit}
            buttonText={"Adopt"}
            isLoading={isLoading}
            className={"mb-3"}
            textColor={"blackColor"}
          />
          <button
            onClick={onClick}
            className="bg-offCoffee rounded-[20px] py-1.5 px-5 md:px-7 mt-2 md:mt-0 max-h-10 "
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInformationBoxWithBack;
