import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetListOfMetricsManager = () => {
  return useQuery(["metricsList"], async () => {
    try {
      const [response] = [await AxiosWithToken.get(`/settings/metrics`)];
      console.log(
        `i am checking this ${response.data.data[0].metric.measurements[0].image}`
      );
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data}`);
    }
  });
};

export default useGetListOfMetricsManager;
