import { useQuery } from "react-query";
import Axios from "../../../../constants/api_management/MyHttpHelper";

const useGetSingleProfileManager = (profileId) => {
  return useQuery(["singleProfile", profileId], async () => {
    try {
      console.log(profileId);
      const [response] = [await Axios.get(`/user/profiles/${profileId}`)];
      console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data}`);
    }
  });
};

export default useGetSingleProfileManager;
