// store.js
import { configureStore, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isInches: true, // Set your initial value explicitly as either true or false
};

const appSlice = createSlice({
  name: "metricToggle",
  initialState,
  reducers: {
    setInches: (state, action) => {
      state.isInches = action.payload; // Use the payload to explicitly set isInches
    },
  },
});

export const { setInches } = appSlice.actions;

export default configureStore({
  reducer: {
    metricToggle: appSlice.reducer,
  },
});
