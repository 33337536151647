import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import PricingTable from "../../../../generalComponents/PricingTable";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import useCreatePlanManager from "../controller/createPlanController";
import CustomButton from "../../../../generalComponents/Button";
import { toast } from "react-toastify";

const PricingPage = () => {
  const [planDetail, setPlanDetail] = useState({
    name: "",
    price: 0,
    profile_limit: 0,
    discount: 0,
  });

  const { postCaller, isLoading, data, isSuccess, error } =
    useCreatePlanManager();

  const handleSubmit = async () => {
    const details = {
      features: {
        allow_share: true,
      },
      active: true,
      name: planDetail.name,
      description: "Access to all features",
      price: planDetail.price,
      limit: -1,
      duration: 30,
      isActive: true,
      discount: planDetail.discount,
      profile_limit: planDetail.profile_limit,
    };
    await postCaller(details);
    setPlanDetail({
      name: "",
      price: 0,
      profile_limit: 0,
      discount: 0,
    });
  };

  return (
    <BaseDashboardNavigation title={"Pricing"}>
      <div className="md:flex md:space-x-[20px] space-y-20 md:space-y-5 relative h-full w-full md:w-[98%]">
        <div className="max-w-full md:w-[60%]">
          <PricingTable />
        </div>

        {/* create or edit container */}
        <div className="rounded-[20px] max-w-full md:w-[40%]  mr-7 p-5 bg-lightGrey/20 py-10 ">
          <p className="text-18px mb-4 font-bold">Add Pricing</p>

          <InputWithFullBoarder
            label={"Plan Name"}
            className={"mb-4"}
            value={planDetail.name}
            onChange={(e) =>
              setPlanDetail({ ...planDetail, name: e.target.value })
            }
          />
          <InputWithFullBoarder
            label={"Cost per month"}
            className={"mb-4"}
            value={planDetail.price}
            onChange={(e) =>
              setPlanDetail({ ...planDetail, price: parseInt(e.target.value) })
            }
          />

          <div className="flex items-center">
            <div className="flex w-full">
              <InputWithFullBoarder
                label={"Max Profiles"}
                type={"number"}
                className={"mb-4 w-[90%]"}
                value={planDetail.profile_limit}
                onChange={(e) =>
                  setPlanDetail({
                    ...planDetail,
                    profile_limit: parseInt(e.target.value),
                  })
                }
              />
              <InputWithFullBoarder
                label={"Discount"}
                className={"mb-4 w-[80%]"}
                type={"number"}
                value={planDetail.discount}
                onChange={(e) =>
                  setPlanDetail({
                    ...planDetail,
                    discount: parseInt(e.target.value),
                  })
                }
              />
            </div>
            <p className="text-16px font-bold mr-3">%</p>
          </div>

          <CustomButton
            buttonText={"Add Plan"}
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default PricingPage;
