import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const HeaderFooter = ({
  clickHome,
  clickFeatures,
  clickSolutions,

  clickDownload,
  children,
}) => {
  return (
    <div>
      <Header
        clickHome={clickHome}
        clickFeatures={clickFeatures}
        clickSolutions={clickSolutions}
        clickDownload={clickDownload}
      />
      {children}
      <Footer />
    </div>
  );
};

export default HeaderFooter;
