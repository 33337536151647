import React from "react";

const FeedTiles = ({ feedDetail, onClick, isCurrent }) => {
  return (
    <div
      onClick={onClick}
      className={`h-[283px] hover:h-[290px] w-[233px] hover:w-[240px] hover:shadow-lg duration-300 shadow-black hover:mb-5 rounded-[20px] bg-white hover:bg-offCoffee/10 text-blackColor  border hover:border-transparent  ${
        isCurrent ? "border-brandOrange  border-2" : "border-lightGrey"
      } p-3 mr-5`}
    >
      <div>
        <img
          className="h-[178px] min-w-[210px] rounded-[10px] object-cover mb-5"
          src={
            feedDetail.cover_image
              ? feedDetail.cover_image
              : "https://images.unsplash.com/photo-1536867520774-5b4f2628a69b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format"
          }
          alt=""
        />
      </div>
      <p className="text-[20px] font-bold truncate text-ellipsis line-clamp-3">
        {feedDetail.title}
      </p>
      <p className="text-[14px] overflow-ellipsis overflow-hidden whitespace-nowrap w-full">
        {feedDetail.description}
      </p>
    </div>
  );
};

export default FeedTiles;
