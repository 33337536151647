import Dashboard from "./modules/dashboard/view/Dashboard";
import AllFeedsPage from "./modules/feeds/view/AllFeedsPage";
import HomePage from "./modules/home/view/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AllMeasurementProfiles from "./modules/measurements/view/AllMeasurementProfiles";
import LoginPage from "./modules/authentication/view/LoginPage";
import CreateAccountPage from "./modules/authentication/view/CreateAccountPage";
import AdminDashboard from "./modules/admin/AdminDashboard";
import UserManagement from "./modules/admin/userManagement/view/UserManagementPage";
import AllProfilesPage from "./modules/admin/profiles/view/AllProfilesPage";

import PricingPage from "./modules/admin/pricing/view/PricingPage";
import MeasurementPage from "./modules/admin/metrics/view/MeasurementPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for styling

import NewMeasurementProfile from "./modules/measurements/view/NewMeasurementProfile";
import SettingsPage from "./modules/settings/SettingsPage";

import AccountVerification from "./modules/authentication/view/AccountVerificationPage";
import ForgotPasswordPage from "./modules/authentication/view/ForgotPasswordPage";
import ResetPasswordPage from "./modules/authentication/view/ResetPasswordPage";
import AdminLoginPage from "./modules/authentication/view/AdminLoginPage";
import AdminLoginVerification from "./modules/authentication/view/AdminLoginVerification";
import UserDetailsPage from "./modules/admin/userManagement/view/UserDetailsPage";
import AdminAllFeedsPage from "./modules/admin/feed/view/AdminAllFeedsPage";
import BroadcastPage from "./modules/admin/broadcast/views/BroadcastPage";
import AdminSettingsPage from "./modules/admin/settings/view/AdminSettingsPage";
import ProfileDetailsPage from "./modules/admin/profiles/view/ProfileDetailsPage";
import PublicProfileDetailsPage from "./modules/admin/profiles/view/ProfileDetailsPage(public)";
import PrivacyPolicyPage from "./modules/dashboard/view/PrivacyPolicyPage";
import TermsAndConditionsPage from "./modules/dashboard/view/TermsAndConditionsPage";
import SupportPage from "./modules/dashboard/view/SupportPage";
import FAQPage from "./modules/dashboard/view/FAQPage";
import TicketsPage from "./modules/admin/tickets/views/TicketsPage";
import PublicProfileDetailsPageView from "./modules/admin/profiles/view/ProfileDetailsPage(view)";
import UserTicketsPage from "./modules/tickets/views/UserTicketsPage";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/dashboard/user-details/:userId"
            element={<UserDetailsPage />}
          />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/login" element={<AdminLoginPage />} />
          <Route
            path="/admin/verification"
            element={<AdminLoginVerification />}
          />
          <Route path="/admin/user-management" element={<UserManagement />} />
          <Route path="/admin/all-profiles" element={<AllProfilesPage />} />
          <Route
            path="/admin/profile-details/:profileId"
            element={<ProfileDetailsPage />}
          />
          <Route path="/admin/all-feeds" element={<AdminAllFeedsPage />} />
          <Route path="/admin/settings" element={<AdminSettingsPage />} />
          <Route path="/share" element={<PublicProfileDetailsPage />} />
          <Route path="/view" element={<PublicProfileDetailsPageView />} />
          <Route
            path="/dashboard/new-profile"
            element={<NewMeasurementProfile />}
          />
          <Route path="/admin/pricing" element={<PricingPage />} />
          <Route path="/admin/tickets" element={<TicketsPage />} />
          <Route path="/admin/measurements" element={<MeasurementPage />} />
          <Route path="/admin/broadcast" element={<BroadcastPage />} />
          <Route path="/dashboard/feeds" element={<AllFeedsPage />} />
          <Route path="/dashboard/settings" element={<SettingsPage />} />
          <Route path="/dashboard/tickets" element={<UserTicketsPage />} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/account-verification"
            element={<AccountVerification />}
          />
          <Route path="/create-account" element={<CreateAccountPage />} />
          <Route
            path="/dashboard/all-profiles"
            element={<AllMeasurementProfiles />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/terms-and-condition"
            element={<TermsAndConditionsPage />}
          />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/faq" element={<FAQPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
