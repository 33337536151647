import React from "react";
import CustomButton from "./Button";

const TicketTiles = ({ ticket, onClick, isCurrent }) => {
  return (
    <div
      onClick={onClick}
      className={`max-h-[283px] hover:max-h-[290px] w-[233px] relative hover:w-[240px] hover:shadow-lg duration-300 shadow-black mb-4 hover:mb-2 rounded-[20px] bg-white hover:bg-offCoffee/10 ${
        isCurrent ? "border-brandOrange border-2 " : "border-lightGrey "
      }  border hover:border-transparent px-3 py-7 mr-5 duration-300`}
    >
      <button
        className={"h-7 rounded-full px-5 py-0.5 bg-offCoffee text-white mb-4"}
      >
        {ticket.status === "open"
          ? "Open"
          : ticket.status === "under_review"
          ? "Under Review"
          : "Closed"}
      </button>
      <p className="text-[20px] font-bold mb-2 relative leading-tight">
        {ticket.title}
      </p>
      <p className="text-[14px] overflow-hidden relative w-[220px] line-clamp-4">
        {ticket.description}
      </p>
    </div>
  );
};

export default TicketTiles;
