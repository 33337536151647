import React from "react";

const MetricsToggle = ({ cmMeasurement, turnCMOn, turnCMOff }) => {
  return (
    <div className="bg-white rounded-[10px] h-[32px]  flex items-center border border-lightGrey">
      <p
        onClick={turnCMOff}
        className={`text-[16px] font-bold ${
          !cmMeasurement ? "bg-offCoffee" : "bg-transparent"
        } py-1 px-2 rounded-lg ${
          !cmMeasurement ? "text-white" : "text-black/60"
        } duration-500 ease-in-out`}
      >
        Inch
      </p>
      <p
        onClick={turnCMOn}
        className={`text-[16px] font-bold ${
          cmMeasurement ? "bg-offCoffee" : "bg-transparent"
        } py-1 px-2 rounded-lg ${
          cmMeasurement ? "text-white" : "text-black/60"
        } duration-500 ease-in-out`}
      >
        CM
      </p>
    </div>
  );
};

export default MetricsToggle;
