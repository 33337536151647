import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import useSubscriptionSettingsManager from "../controller/subscriptionSettingsController";
import CustomButton from "../../../../generalComponents/Button";
import { toast } from "react-toastify";
import useSetPaymentActivationManager from "../../pricing/controller/createPlanController";
import useGetPaymentActivationManager from "../controller/getListOfPlansController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";

const AdminSettingsPage = () => {
  const { updateCaller, isLoading } = useSubscriptionSettingsManager();
  const { postCaller, isLoading: settingPayment } =
    useSetPaymentActivationManager();
  const {
    isLoading: gettingSetting,
    data: setting,
    isSuccess: fetchedSetting,
    isError: settingHasError,
    error: settingError,
  } = useGetPaymentActivationManager();
  const [isFree, setIsFree] = useState(true);
  const [trialDuration, setTrialDuration] = useState(30);
  const [isActive, setIsActive] = useState(false);
  const handleSubmit = async () => {
    const details = {
      isFree: isFree,
      trial_duration: trialDuration,
    };

    // console.log(details);

    await updateCaller(details);
  };

  useEffect(() => {
    if (fetchedSetting && setting) {
      setIsActive(setting.options.paymentActivation);
    }
  }, [fetchedSetting, setting]);

  if (gettingSetting) {
    return <Loader />;
  }

  if (settingHasError) {
    return <ErrorManager errorMessage={settingError.message} />;
  }

  return (
    <BaseDashboardNavigation title={"Settings"} hideSearch={true}>
      <div className="  md:flex md:flex-col w-full md:w-fit  h-full pt-8 mr-7 md:mr-auto">
        <InputWithFullBoarder
          label={"Trial Period Duration (In days)"}
          className={"relative mb-4 w-[95%] md:w-auto"}
          value={trialDuration}
          onChange={(e) => setTrialDuration(parseInt(e.target.value))}
        />
        <p className="text-13px md:text-16px font-semibold mb-2   ">
          Personal Profile Plan Option
        </p>
        <select
          className={`border border-lightGrey mb-10 bg-lightGrey/30 p-2 block w-[95%] md:w-auto rounded-md outline-none focus:outline-none `}
          value={isFree}
          onChange={(e) => setIsFree(e.target.value === "true")}
        >
          <option value={true}>Free</option>
          <option value={false}>Paid</option>
        </select>

        <CustomButton
          buttonText={"Update Settings"}
          onClick={handleSubmit}
          isLoading={isLoading}
        />

        <div className="mt-20 flex flex-col md:flex-row items-start md:items-center justify-start">
          <p className="mb-3 md:mb-0 md:mr-3">
            Activate payment options in iOS
          </p>
          <CustomButton
            buttonText={isActive ? "Deactivate Payment" : "Activate Payment"}
            isLoading={settingPayment}
            onClick={async () => {
              const details = { paymentActivation: !isActive };
              await postCaller(details);
            }}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default AdminSettingsPage;
