import React, { useEffect, useRef, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import MeasurementMetricsTable from "../../../../generalComponents/MeasurementMetricsTable";
import useCreateMetricsManager from "../controllers/createMetricsController";
import CustomButton from "../../../../generalComponents/Button";
import useFileUpload from "../../../fileupload/fileUploadController";
import useGetListOfMetricsManager from "../controllers/getListOfMetricsController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { toast } from "react-toastify";

const MeasurementPage = () => {
  // const [sex, setSex] = useState("Male");
  const [name, setName] = useState("");
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [mainPicture, setMainPicture] = useState(null);
  const [mainPicturePicked, setMainPicturePicked] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedSexIndex, setSelectedSexIndex] = useState(0);

  const [active, setActive] = useState(true);
  // const {
  //   isError: listhasError,
  //   data: list,
  //   error: listError,
  //   isLoading: listLoading,
  //   isSuccess: listLoaded,
  // } = useGetListOfMetricsManager();
  const { postCaller, isLoading, data, isSuccess, error, isError } =
    useCreateMetricsManager();
  const {
    error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: fileLoading,
    data: uploadUrl,
  } = useFileUpload();

  const handleMainPictureUpload = () => {
    return new Promise(async (resolve, reject) => {
      setUploadingPhoto(true);
      try {
        const fileUrl = await uploadFile(mainPicture);
        console.log(`this is the url ${fileUrl}`);
        setUploadingPhoto(false);
        setMainPicturePicked(false);
        resolve(fileUrl); // Resolve the promise with the photo value
        return fileUrl;
      } catch (error) {
        console.error("Photo upload error:", error);
        setUploadingPhoto(true);
        reject(error); // Reject the promise with the error
      } finally {
        setUploadingPhoto(false);
      }
    });
  };
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleSubmit = async () => {
    if (!name) {
      toast.error("Please enter the name");
      return;
    }
    const mainPictureUrl = mainPicturePicked
      ? await handleMainPictureUpload()
      : "";

    const details =
      // metric: updatedMetric,
      {
        metric: {
          sex: selectedSexIndex === 0 ? "Female" : "Male",
          measurement: {
            name: name,
            active: active,
            image: mainPictureUrl,
          },
        },
      };
    await postCaller(details);
    setName("");
    setSelectedFileName("");
  };

  // if (listLoading) {
  //   return <Loader />;
  // }

  // if (listhasError) {
  //   return (
  //     <div>
  //       <ErrorManager errorMessage={listError.message} />
  //       {/* <ErrorManager errorMessage={error.deleteError} /> */}
  //     </div>
  //   );
  // }

  return (
    <BaseDashboardNavigation title={"Measurements"} hideSearch={true}>
      <div className="flex flex-col md:flex-row md:space-x-[20px] space-y-20 md:space-y-5 relative h-full w-full md:w-[98%] ">
        <div className="max-w-full md:w-[60%] order-2 md:order-none mt-10 md:mt-0 md:h-[85vh] relative md:overflow-y-auto scrollbar-hide">
          <MeasurementMetricsTable />
        </div>

        {/* create or edit container */}
        <div className="rounded-[20px] max-w-full md:w-[40%]  mr-7 p-5 py-10 bg-lightGrey/20 order-1 md:order-none ">
          <div className=" flex items-center justify-between mb-10">
            <p className="text-18px  font-bold">Add Measurements</p>
            <div className="flex md:justify-end ">
              <div className="bg-white rounded-[10px] h-[32px] flex items-center border border-lightGrey">
                <p
                  onClick={() => {
                    setSelectedSexIndex(0);
                  }}
                  className={`text-[16px] font-bold ${
                    selectedSexIndex === 0 ? "bg-offCoffee" : "bg-transparent"
                  } py-1 px-2 rounded-lg ${
                    selectedSexIndex === 0 ? "text-white" : "text-black/60"
                  } duration-500 ease-in-out`}
                >
                  Female
                </p>
                <p
                  onClick={() => {
                    setSelectedSexIndex(1);
                  }}
                  className={`text-[16px] font-bold ${
                    selectedSexIndex === 1 ? "bg-offCoffee" : "bg-transparent"
                  } py-1 px-2 rounded-lg ${
                    selectedSexIndex === 1 ? "text-white" : "text-black/60"
                  } duration-500 ease-in-out`}
                >
                  Male
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mb-10 w-full ">
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(event) => {
                const files = event.target.files;
                if (files && files.length > 0) {
                  setMainPicture(files[0]);
                  setSelectedFileName(files[0].name);
                  setMainPicturePicked(true);
                } else {
                  setMainPicture(null);
                  setMainPicturePicked(false);
                }
              }}
            />
            <CustomButton
              isLoading={fileLoading}
              loader={"uploading image"}
              onClick={handleClick}
              className={"mr-4"}
              buttonText={"Upload Image"}
            />

            <p className=" text-13px  font-normal">
              {selectedFileName ?? "Upload image"}
            </p>
          </div>

          <InputWithFullBoarder
            label={"Measurement Name"}
            className={"mb-4"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <select
            className={`border border-lightGrey w-full mb-20 bg-lightGrey/30 p-2 rounded-md outline-none focus:outline-none `}
            value={active ? "true" : "false"} // Set strings representing boolean values
            onChange={(e) => {
              setActive(e.target.value === "true"); // Convert the string to a boolean
            }}
          >
            <option value="true">Active</option>
            <option value="false">InActive</option>
          </select>

          <CustomButton
            buttonText={"Update Metrics"}
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default MeasurementPage;
