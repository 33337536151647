import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import { Pagination } from "@mui/material";
import useAllUsersManager from "../controllers/getAllUsersController";
import Loader from "../../../../generalComponents/Loader";
import useGetAnalytics from "../../profiles/controllers/getAnalytics";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import FullAllUsersTable from "../../../../generalComponents/FullAllUsersTable";
import PaginationRounded from "../../../../generalComponents/Pagination";

const UserManagementPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handlePageChange = (page) => {
    if (page === currentPage) return; // No action needed if already on the current page

    if (page === data.pagination.nextPage) {
      // Move to the next page
      setCurrentPage(page);
      refetch();
    } else if (page === data.pagination.prevPage) {
      // Move to the previous page
      setCurrentPage(page);
      refetch();
    }
  };
  const { data, isError, error, isLoading, isSuccess, refetch } =
    useAllUsersManager(currentPage, searchQuery);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        setSearchQuery(`&fullname=${searchTerm}`);
        refetch();
      } else {
        setSearchQuery(""); // Clear the search query
        refetch();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (isSuccess) {
      setCurrentPage(data.pagination.currentPage);
    }
  }, [data, isSuccess]);
  const {
    data: analytics,
    error: analyticsError,
    isError: analyticsHasError,
    isLoading: loadingAnalytics,
  } = useGetAnalytics();

  if (isLoading || loadingAnalytics) {
    return <Loader />;
  }

  if (isError || analyticsHasError) {
    return (
      <div>
        {isError && <ErrorManager errorMessage={error.message} />}
        {analyticsHasError && (
          <ErrorManager errorMessage={analyticsError.message} />
        )}
      </div>
    );
  }

  return (
    <BaseDashboardNavigation
      title={"User Management"}
      value={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        console.log(searchTerm);
      }}
    >
      <div className="flex flex-col h-[90vh]">
        {/* analytics */}
        <div className="flex flex-col md:flex-row justify-between w-[90%] text-white md:space-x-[13px] space-y-[13px] md:space-y-0 py-1 mb-[45px] md:mb-12 ">
          <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-offCoffee p-8 w-full  flex flex-col justify-center">
            <p className="text-30px font-semibold ">
              {analytics.data.totalUsers}
            </p>
            <p className="text-16px font-semibold ">Total Users</p>
          </div>
          <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-brandOrange p-8  w-full flex flex-col justify-center">
            <p className="text-30px font-semibold ">
              {analytics.data.totalAdmins}
            </p>
            <p className="text-16px font-semibold ">Total Admin</p>
          </div>
          <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-brandGreen p-8 w-full   flex flex-col justify-center">
            <p className="text-30px font-semibold ">
              {analytics.data.activeSubscribers}
            </p>
            <p className="text-16px font-semibold ">Active Subscribers</p>
          </div>
          <div className="hover:shadow-xl duration-150 h-[127px] rounded-[20px] bg-brandGreen p-8 w-full   flex flex-col justify-center">
            <p className="text-30px font-semibold ">
              {analytics.data.expiredSubscribers}
            </p>
            <p className="text-16px font-semibold ">Expired Subscribers</p>
          </div>
        </div>

        {/* user tables */}
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          <FullAllUsersTable users={data.users} />
        </div>

        {data.users.length && (
          <div className="my-10 flex items-center justify-center">
            <PaginationRounded
              count={data?.pagination?.pageTotal}
              defaultPage={data?.pagination?.currentPage}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )}
      </div>
    </BaseDashboardNavigation>
  );
};

export default UserManagementPage;
