import React from "react";

const HowItWork = ({ id }) => {
  return (
    <section
      id={id}
      class="py-20 mb-14 sm:mb-0 bg-white sm:py-1 lg:py-2 xl:py-3 "
    >
      <div class="px-5 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-20 xl:gap-x-40">
          <div>
            <img
              class="object-cover w-full rounded-2xl"
              src="https://landingfoliocom.imgix.net/store/collection/saasui/images/how-it-works/1/girl-with-laptop.png"
              alt=""
            />
          </div>

          <div>
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl ">
              A simple, yet effective solution!
            </h2>

            <div class="flow-root mt-12 xl:mt-20">
              <ul class="-my-8 divide-y divide-gray-200 xl:-my-10">
                <li class="flex flex-col py-8 xl:py-10 sm:flex-row sm:items-start">
                  <span class="text-4xl font-medium text-gray-300"> 01. </span>
                  <p class="mt-6 text-xl font-normal text-gray-900 sm:mt-0 sm:ml-9">
                    <span class="font-semibold mr-1">
                      Create a measurement profiles
                    </span>
                    for yourself, family and for your clients.
                  </p>
                </li>

                <li class="flex flex-col py-8 xl:py-10 sm:flex-row sm:items-start">
                  <span class="text-4xl font-medium text-gray-300"> 02. </span>
                  <p class="mt-6 text-xl font-normal text-gray-900 sm:mt-0 sm:ml-9">
                    Share your profile
                    <span class="font-semibold"> with your stylist</span> and as
                    many people as you'd like.
                  </p>
                </li>

                <li class="flex flex-col py-8 xl:py-10 sm:flex-row sm:items-start">
                  <span class="text-4xl font-medium text-gray-300"> 03. </span>
                  <p class="mt-6 text-xl font-normal text-gray-900 sm:mt-0 sm:ml-9">
                    See all profiles created by others for you
                    <span class="font-semibold ml-1">
                      & track changes to your measurements.
                    </span>
                    .
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p className="text-16px font-medium items-start md:items-center text-center justify-center px-4 h-10 mt-20 w-[90%]  sm:mb-20 ">
        Own a <span className="font-bold uppercase ml-1"> fashion house</span>?
        Manage and track all your clients' measurement database in one place.
        <span className="font-bold underline mt-4 md:mt-0 md:ml-3">
          Create a free account today!
        </span>
      </p>
    </section>
  );
};

export default HowItWork;
