import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MeasurementDetailsTiles from "./MeasurementDetailsTiles";
import MetricsToggle from "./MetricsToggle";
import { setInches } from "../constants/redux/store";
import { is } from "date-fns/locale";

const MeasurementProfileDetails = ({
  measurements,
  isCM,
  onUpdateIndex,
  unitName,
  isHistory,
}) => {
  const [cmMeasurement, setCMMeasurement] = useState(isCM);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isInches = useSelector((state) => state.metricToggle.isInches);
  const dispatch = useDispatch();
  useEffect(() => {
    // Update cmMeasurement when either firstLoad changes or currentIndex changes
    setCMMeasurement(isInches);

    onUpdateIndex(currentIndex);
  }, [isInches, currentIndex]);

  return (
    <div className=" rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 flex flex-col md:items-end justify-between ">
      <div className="flex justify-between items-center mb-[24px] w-full">
        <p className="text-[25px] font-bold">My Profile</p>
        <MetricsToggle
          cmMeasurement={!cmMeasurement}
          turnCMOff={() => {
            setCMMeasurement(false);
            dispatch(setInches(true));
            console.log(isInches);
          }}
          turnCMOn={() => {
            setCMMeasurement(true);
            dispatch(setInches(false));
            console.log(isInches);
          }}
        />
      </div>
      {measurements.map((measurement, index) => {
        return (
          <MeasurementDetailsTiles
            isHistory={isHistory}
            key={index}
            unitName={unitName}
            measurementDetail={measurement}
            isCM={!cmMeasurement}
            onClick={() => setCurrentIndex(index)}
            onMouseEnter={() => setCurrentIndex(index)}
          />
        );
      })}
    </div>
  );
};

export default MeasurementProfileDetails;
