import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetSingleTicketController = (ticketId, enabled = false) => {
  return useQuery(
    ["singleTicket", ticketId],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/support/tickets/${ticketId}`),
        ];
        console.log(`i am checking this ${response.data.data.status}`);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`${error.response.data.message}`);
      }
    },
    { enabled: enabled }
  );
};

export default useGetSingleTicketController;
