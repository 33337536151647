import { useQuery } from "react-query";

import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetCSVFileManager = (enabled = false, sex = "") => {
  return useQuery(
    ["allUserProfiles", sex],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/user/profiles/template/${sex}`),
        ];
        console.log(`i am checking this ${response.data}`);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { enabled: enabled }
  );
};

export default useGetCSVFileManager;
