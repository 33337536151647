import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetProfileHistoryManager = (profileId, enabled) => {
  return useQuery(
    ["profileHisory", profileId],
    async () => {
      try {
        console.log(profileId);
        const [response] = [
          await AxiosWithToken.get(`/user/profiles/${profileId}/history`),
        ];
        console.log(`i am checking this ${response.data}`);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data}`);
      }
    },
    {
      enabled: enabled, // Set the enabled option based on the provided value
    }
  );
};

export default useGetProfileHistoryManager;
