import React from "react";
import { RxDividerVertical } from "react-icons/rx/index.esm";

const MeasurementDetailsTiles = React.memo(
  ({ measurementDetail, isCM, onClick, onMouseEnter, unitName, isHistory }) => {
    const capitalizeFirstLetter = (string) => {
      if (typeof string === "string" && string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        return string; // Return the input as is if it's not a valid string
      }
    };

    const formatValue = (value) => {
      if (unitName === "cm") {
        if (!isCM) {
          // Convert from centimeters to inches
          return `${(value / 2.54).toFixed(2)} inches`;
        } else {
          // Keep the original value in centimeters
          return `${value} cm`;
        }
      } else {
        if (!unitName !== "cm") {
          if (isCM) {
            // Convert from inches to centimeters
            return `${(value * 2.54).toFixed(2)} cm`;
          } else {
            // Keep the original value in inches
            return `${value} inches`;
          }
        }
      }
    };

    return (
      <div
        onClick={onClick}
        onMouseEnter={onMouseEnter} // Add onMouseEnter event
        className="bg-white rounded-[15px] h-[65px] mb-[10px] w-full flex items-center border border-lightGrey hover:border-offCoffee justify-between p-5 md:p-10 "
      >
        <div className="flex items-center justify-start">
          <div className="h-5 w-5 rounded-full bg-offCoffee mr-3" />
          <p className="text-[16px] font-bold">
            {measurementDetail.name &&
              capitalizeFirstLetter(
                isHistory
                  ? measurementDetail.name ?? "Undefined"
                  : measurementDetail.name.name ??
                      measurementDetail.name ??
                      "Undefined"
              )}
          </p>
        </div>
        <RxDividerVertical className="h-[50px]" />
        <p className="text-[16px]">{formatValue(measurementDetail.value)}</p>
      </div>
    );
  }
);

export default MeasurementDetailsTiles;
