import React, { useEffect, useRef, useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import useGetListOfMetricsManager from "../../admin/metrics/controllers/getListOfMetricsController";
import Loader from "../../../generalComponents/Loader";
import ErrorManager from "../../../generalComponents/ErrorManager";
import useCreateProfileForOthersManager from "../controllers/createProfileForOthersController";
import CustomButton from "../../../generalComponents/Button";
import AlertDialog from "../../../generalComponents/AlertDialog";
import useGetCSVFileManager from "../controllers/getCSVfileController";
import useUploadBulkProfilesManager from "../controllers/uploadBulkProfilesController";
import { toast } from "react-toastify";
import MetricsToggle from "../../../generalComponents/MetricsToggle";

const NewMeasurementProfile = () => {
  const [firstPart, setFirstPart] = useState(true);
  const [sex, setSex] = useState("male");
  const [downloadSex, setDownloadSex] = useState("male");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [enableDownload, setEnableDownload] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);
  const [selectedSexIndex, setSelectedSexIndex] = useState(0);
  const {
    isError: listhasError,
    data: list,
    error: listError,
    isSuccess,
    isLoading: listLoading,
  } = useGetListOfMetricsManager();

  const [newMeasurementList, setNewMeasurementList] = useState([]);
  const [cmMeasurement, setCMMeasurement] = useState(false);
  const [currentMetricIndex, setCurrentMetricsIndex] = useState(0);
  const [selectedFileName, setSelectedFileName] = useState("");

  function downloadFile(fileURL) {
    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.target = "_blank"; // Open in a new tab/window, you can remove this line if you want to download in the same tab
    anchor.download = "raizonMeasurementTemplate.xlsx"; // Set the desired file name here

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  const {
    postCaller,
    isLoading,
    data,
    isSuccess: created,
    error,
    isError,
  } = useCreateProfileForOthersManager();

  const handleSubmit = async () => {
    const dataToSend = {
      fullname: name,
      email: email,
      sex: sex,
      phone: phone,
      unit: cmMeasurement ? "cm" : "inch",
      allow_edit: false,
      measurement: newMeasurementList,
    };

    await postCaller(dataToSend);

    setOpenResponse(true);

    setName("");
    setEmail("");
    setPhone("");
    setFirstPart(true);
    setSex("male");
  };

  const {
    isLoading: downloading,
    isSuccess: downloaded,
    data: file,
    refetch,
    error: downloadError,
  } = useGetCSVFileManager(enableDownload, downloadSex);

  const handleDownloadRequest = async () => {
    setEnableDownload(true);
    await refetch();
    if (downloaded) {
      console.log(file.data.url);
      downloadFile(file.data.url);
    } else if (downloadError) {
      alert(downloadError.message);
    }
  };

  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };

  const {
    data: uploadResponse,
    error: uploadError,
    isLoading: uploading,
    isSuccess: uploaded,
    postCaller: upload,
  } = useUploadBulkProfilesManager();

  useEffect(() => {
    setSelectedSexIndex(sex === "male" ? 1 : 0);
  }, [sex]);

  useEffect(() => {
    if (isSuccess) {
      const newMeasurements = list.data[
        selectedSexIndex
      ].metric.measurements.map((measurement) => ({
        // name: measurement.measurement.name,
        measurement: measurement.measurement.id,
        value: "",
      }));
      setNewMeasurementList(newMeasurements);
    }
  }, [isSuccess, selectedSexIndex]);

  if (listLoading) {
    return <Loader />;
  }

  if (listhasError) {
    return (
      <div>
        {listhasError && <ErrorManager errorMessage={listError.message} />}
      </div>
    );
  }
  return (
    <BaseDashboardNavigation
      title={"New Measurement Profile"}
      hideSearch={true}
    >
      <dialog
        id="my_modal_6"
        className="modal modal-bottom sm:modal-middle mx-auto"
      >
        <form
          method="dialog"
          className="modal-box w-full md:w-96 bg-whiteColor"
        >
          <h3 className="font-bold text-lg">Choose a gender</h3>
          <p className="py-4 mr-2">
            You can only download file for each sex per time.
          </p>
          <select
            className={`border border-lightGrey w-full mb-10 bg-lightGrey/30 p-2 rounded-md outline-none focus:outline-none `}
            value={downloadSex}
            onChange={(e) => setDownloadSex(e.target.value)}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <div className="space-x-0 md:space-x-3 mx-auto max-w-max flex justify-center">
            <CustomButton
              onClick={handleDownloadRequest}
              isLoading={downloading}
              className={"mt-10"}
              buttonText={"Download File"}
            />
          </div>
          <div className="modal-action">
            {/* if there is a button in form, it will close the modal */}
            <CustomButton buttonText={"Cancel"} />
          </div>
        </form>
      </dialog>
      <div className="flex items-center mb-6">
        <CustomButton
          buttonText={"Download CSV"}
          className={"mr-7 w-full md:w-60"}
          isLoading={downloading}
          onClick={() => {
            window.my_modal_6.showModal();
          }}
        />

        <div className="flex items-center justify-start  w-full ">
          <input
            ref={inputRef}
            type="file"
            accept=".xlsx"
            style={{ display: "none" }}
            onChange={async (event) => {
              const files = event.target.files;
              if (files && files.length > 0) {
                setSelectedFileName(files[0].name);

                // const details = { file: files[0] };
                const formData = new FormData();
                formData.append("file", files[0]);
                await upload(formData);
                setSelectedFileName("");

                if (uploaded) {
                  console.log(uploadResponse.message);
                } else if (uploadError) {
                  alert(uploadError.message);

                  console.log(uploadError.message);
                }
              } else {
              }
            }}
          />
          <CustomButton
            isLoading={uploading}
            loader={"uploading file"}
            onClick={handleClick}
            className={"mr-4"}
            buttonText={"Upload File"}
          />

          <p className=" text-13px  font-normal">
            {selectedFileName ?? "Upload Bulk Profiles"}
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-[20px] space-y-20 md:space-y-5 relative h-full w-full md:w-[98%]">
        {/* profile details section */}

        {firstPart && (
          <div className="rounded-[20px] mt-10 md:mt-0  max-w-full md:w-[40%] order-2 md:order-none  mr-7 p-5 py-10 bg-lightGrey/20 md:h-[80vh]  overflow-y-auto scrollbar-hide ">
            <div className="flex items-center justify-between w-full mb-10 ">
              <p className="text-18px font-bold">Profile Details</p>
              {!firstPart && (
                <MetricsToggle
                  cmMeasurement={cmMeasurement}
                  turnCMOff={() => setCMMeasurement(false)}
                  turnCMOn={() => setCMMeasurement(true)}
                />
              )}
            </div>

            <InputWithFullBoarder
              label={"Profile Name"}
              id={"name"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <InputWithFullBoarder
              label={"Phone Number"}
              id={"phone"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <InputWithFullBoarder
              label={"Email"}
              type={"email"}
              id={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="text-13px md:text-16px font-semibold mb-2">
              Choose Sex
            </p>
            <select
              className={`border border-lightGrey w-full mb-10 bg-lightGrey/30 p-2 rounded-md outline-none focus:outline-none `}
              value={sex}
              onChange={(e) => setSex(e.target.value)}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>

            <button
              className="rounded-[20px] py-1.5 px-5 mr-3  bg-offCoffee font-semibold"
              onClick={() => setFirstPart(false)}
            >
              Next
            </button>
          </div>
        )}

        {/* measurement section */}
        {!firstPart && (
          <div className="rounded-[20px] mt-10 md:mt-0 order-2 md:order-none max-w-full md:w-[40%]  mr-7 p-5 py-10 bg-lightGrey/20 md:h-[80vh]  overflow-y-auto scrollbar-hide ">
            <div className="flex items-center justify-between w-full mb-10 ">
              <p className="text-18px font-bold">Profile Details</p>
              <MetricsToggle
                cmMeasurement={cmMeasurement}
                turnCMOff={() => setCMMeasurement(false)}
                turnCMOn={() => setCMMeasurement(true)}
              />
            </div>

            {newMeasurementList.map((measurement, index) => (
              <InputWithFullBoarder
                key={index}
                label={`${
                  list.data[selectedSexIndex].metric.measurements[
                    index
                  ].measurement.name
                    .charAt(0)
                    .toUpperCase() +
                  list.data[selectedSexIndex].metric.measurements[
                    index
                  ].measurement.name.slice(1)
                } (${cmMeasurement ? "cm" : "inches"})`}
                value={measurement.value}
                onClick={() => setCurrentMetricsIndex(index)}
                onChange={(e) => {
                  const updatedMeasurementList = [...newMeasurementList];
                  console.log(e.target.value);
                  updatedMeasurementList[index].value = e.target.value;
                  setNewMeasurementList(updatedMeasurementList);
                }}
              />
            ))}

            <div className="flex w-full items-center justify-between">
              <button
                className="rounded-[20px] py-1.5 px-5 mr-3  bg-offCoffee font-semibold"
                onClick={() => setFirstPart(true)}
              >
                Back
              </button>

              <CustomButton
                buttonText={"Create Profile"}
                onClick={handleSubmit}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
        {/* measurement Image */}
        {!firstPart && (
          <div className="order-1 md:order-none rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 h-[400px] md:h-[80vh] w-[90%] md:w-[26%] mr-7 relative md:mr-0">
            <img
              className="object-contain h-full w-full"
              src={
                list.data[selectedSexIndex].metric.measurements[
                  currentMetricIndex
                ].measurement.image
              }
              alt=""
            />
          </div>
        )}
      </div>
    </BaseDashboardNavigation>
  );
};

export default NewMeasurementProfile;
