import React, { useEffect, useState } from "react";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";

import CustomButton from "../../../generalComponents/Button";
import useEditMeasurementProfileManager from "../../settings/controllers/editMeasurementProfileController";
import MetricsToggle from "../../../generalComponents/MetricsToggle";
import { toast } from "react-toastify";

const EditMeasurementComponent = ({
  measurement,
  isCM,
  profileId,
  unitName,
  onUpdateIndex,
  showEditFeedback,
}) => {
  const {
    updateCaller,
    data: updateResponse,
    isLoading: updateIsLoading,
    isSuccess: updateSuccess,
    error: updateError,
  } = useEditMeasurementProfileManager(profileId);

  const [cmMeasurement, setCMMeasurement] = useState(!isCM);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [measurementData, setMeasurementData] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [storedMeasurement, setStoredMeasurement] = useState([]);

  // Function to update measurement values based on the unit of measurement
  const _updateMeasurementControllersValue = (isCM) => {
    const updatedData = storedMeasurement.map((measurement, index) => {
      const newValue = formatValue(measurement.value, !isCM); //now we are checking for inches and not cm anylonger
      return { ...measurement, value: newValue };
    });
    setMeasurementData(updatedData);
  };

  // Update measurementData when data is successfully fetched
  useEffect(() => {
    const initialMeasurementData = measurement.map((measurement) => ({
      measurement: measurement.id,
      value: measurement.value || "",
    }));
    setMeasurementData(initialMeasurementData);

    // Store the measurement prop values
    setStoredMeasurement(initialMeasurementData);

    setCMMeasurement(isCM);
  }, [measurement, isCM]);

  useEffect(() => {
    onUpdateIndex(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (showEditFeedback) {
      setShowFeedback(true);
    } else {
      setShowFeedback(false);
    }
  }, [showEditFeedback]);

  const handleSubmit = async () => {
    const details = {
      unit: cmMeasurement ? "cm" : "inch",
      measurement: measurementData,
    };
    await updateCaller(details);
  };

  const formatValue = (value) => {
    if (unitName === "cm") {
      if (!cmMeasurement) {
        // Convert from centimeters to inches
        return `${(value / 2.54).toFixed(2)}`;
      } else {
        // Keep the original value in centimeters
        return `${value} `;
      }
    } else {
      if (!unitName !== "cm") {
        if (cmMeasurement) {
          // Convert from inches to centimeters
          return `${(value * 2.54).toFixed(2)} `;
        } else {
          // Keep the original value in inches
          return `${value}`;
        }
      }
    }
  };
  useEffect(() => {
    _updateMeasurementControllersValue(cmMeasurement);
  }, [cmMeasurement]);

  return (
    <div className="md:flex md:h-[70vh] w-full">
      <div className="rounded-[20px] max-w-full w-full   p-5 md:p-10 py-10 bg-lightGrey/20 md:h-[70vh]  overflow-y-auto scrollbar-hide ">
        <div className="w-full items-center justify-between flex mb-[24px] ">
          <p className="text-[25px] font-bold"> Edit Profile</p>
          <MetricsToggle
            cmMeasurement={cmMeasurement}
            turnCMOff={() => {
              setCMMeasurement(false);
              // _updateMeasurementControllersValue(false);
            }}
            turnCMOn={() => {
              setCMMeasurement(true);
            }}
          />
        </div>
        {measurementData.map((measurementDetails, index) => (
          <InputWithFullBoarder
            key={index}
            label={`${
              measurement[index].name &&
              measurement[index].name.name.charAt(0).toUpperCase() +
                measurement[index].name.name.slice(1)
            }  `}
            value={measurementDetails.value}
            onClick={() => {
              setCurrentIndex(index);
            }}
            onChange={(event) => {
              const updatedData = [...measurementData];
              updatedData[index].value = event.target.value;
              setMeasurementData(updatedData);
            }}
          />
        ))}

        <CustomButton
          onClick={handleSubmit}
          buttonText={"Update Measurement"}
          isLoading={updateIsLoading}
        />
      </div>
    </div>
  );
};

export default EditMeasurementComponent;
