import { useQuery } from "react-query";

import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";
import { toast } from "react-toastify";

const useGetListofCreatedProfiles = (searchQuery = "", activePage = 1) => {
  // toast.success(searchQuery);
  return useQuery(["allUserProfiles", searchQuery, activePage], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/user/profiles/other?page=${activePage}${searchQuery}`
        ),
      ];
      console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetListofCreatedProfiles;
