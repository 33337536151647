import React, { useEffect, useRef, useState } from "react";

import { formatDistanceToNow, sub } from "date-fns";

import useUserTicketsListManager from "../controller/userTicketListController";
import useGetSingleTicketController from "../../admin/tickets/controller/getSingleTicketController";
import useFileUpload from "../../fileupload/fileUploadController";
import useTicketResponseManager from "../../admin/tickets/controller/sendResponseController";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import Loader from "../../../generalComponents/Loader";
import ErrorManager from "../../../generalComponents/ErrorManager";
import TicketTiles from "../../../generalComponents/TicketsTiles";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import useSendSupportManager from "../../dashboard/controller/send_support_controller";
import useGetUserDetailsManager from "../../settings/controllers/get_UserDetails_controller";
import { toast } from "react-toastify";

const UserTicketsPage = () => {
  const [subject, setSubject] = useState("");
  const [supportRequest, setSupportRequest] = useState("");
  const [newSupportMedia, setNewSupportMedia] = useState([]);
  const [newSupportMediaPicked, setNewSupportMediaPicked] = useState(false);
  const [uploadingNewSupportMedia, setUploadingewSupportMedia] =
    useState(false);
  const {
    data: userDetails,
    isLoading: loadingUserDetails,
    isError: userDetailsHasError,
    error: userDetailsError,
  } = useGetUserDetailsManager();

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleSupportRequestChange = (event) => {
    setSupportRequest(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Add your submit logic here
    const galleryUrls = newSupportMediaPicked
      ? await handleNewSupportMediaUpload()
      : [];
    console.log(`the uploaded gallery ${galleryUrls}`);
    // Dynamically determine media type based on URL
    const details = {
      email: userDetails.data.user.email,
      title: subject,
      description: supportRequest,
      attachments: galleryUrls,
    };
    console.log(details);
    if (subject && supportRequest) {
      await sendSupport(details);
      setNewSupportMedia([]);
    } else {
      toast.warn("Please all both Subject and Details");
    }

    setSubject("");
    setSupportRequest("");
  };

  const { sendSupport, isLoading: creatingMessage } = useSendSupportManager();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reply, setReply] = useState("");

  const [currentTicketId, setCurrentTicketId] = useState("");

  const [media, setMedia] = useState([]);
  const [mediaPicked, setMediaPicked] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);

  function formatDateAgo(date) {
    const options = { addSuffix: true };
    return formatDistanceToNow(new Date(date), options);
  }
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };
  const inputNewSupportRef = useRef(null);
  const handleNewSupportClick = () => {
    inputNewSupportRef.current.click();
  };

  const { isLoading, postCaller } = useTicketResponseManager();

  const {
    isError: ticketHasError,
    data: tickets,
    error: ticketFetchError,
    isLoading: fetchingtickets,
    isSuccess: ticketsFetched,
  } = useUserTicketsListManager();

  const [enabled, setEnabled] = useState(false);

  const {
    data: singleTicketData,
    isLoading: loadingSingleTicket,
    refetch,
  } = useGetSingleTicketController(currentTicketId, enabled);

  const { handleFileUpload: uploadFile, isLoading: fileLoading } =
    useFileUpload();

  useEffect(() => {
    if (ticketsFetched) {
      if (tickets.tickets.length) {
        setCurrentTicketId(tickets.tickets[currentIndex].id);
      }
      setEnabled(true);
      refetch();
    }
  }, [tickets, ticketsFetched]);

  const handleNewSupportMediaUpload = () => {
    return new Promise(async (resolve, reject) => {
      setUploadingewSupportMedia(true);
      try {
        const uploadPromises = newSupportMedia.map(async (file) => {
          const fileUrl = await uploadFile(file);
          console.log(`Uploaded file URL: ${fileUrl}`);
          return fileUrl;
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        console.log(`Uploaded ${uploadedFiles.length} files in total`);

        setUploadingewSupportMedia(false);
        setNewSupportMediaPicked(false);
        resolve(uploadedFiles); // Resolve the promise with the array of uploaded file URLs
        return uploadedFiles;
      } catch (error) {
        console.error("Media upload error:", error);
        setUploadingewSupportMedia(true);
        reject(error); // Reject the promise with the error
      } finally {
        setUploadingewSupportMedia(false);
      }
    });
  };
  const handleMediaUpload = () => {
    return new Promise(async (resolve, reject) => {
      setUploadingMedia(true);
      try {
        const uploadPromises = media.map(async (file) => {
          const fileUrl = await uploadFile(file);
          console.log(`Uploaded file URL: ${fileUrl}`);
          return fileUrl;
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        console.log(`Uploaded ${uploadedFiles.length} files in total`);

        setUploadingMedia(false);
        setMediaPicked(false);
        resolve(uploadedFiles); // Resolve the promise with the array of uploaded file URLs
        return uploadedFiles;
      } catch (error) {
        console.error("Media upload error:", error);
        setUploadingMedia(true);
        reject(error); // Reject the promise with the error
      } finally {
        setUploadingMedia(false);
      }
    });
  };

  if (fetchingtickets || loadingUserDetails) {
    return <Loader />;
  }

  if (ticketHasError || userDetailsHasError) {
    return (
      <div>
        {userDetailsHasError && (
          <ErrorManager errorMessage={userDetailsError.message} />
        )}
        {ticketHasError && (
          <ErrorManager errorMessage={ticketFetchError.message} />
        )}
      </div>
    );
  }
  return (
    <BaseDashboardNavigation title={"Tickets"}>
      <div className="md:flex md:space-x-[20px] space-y-20 md:space-y-5 relative h-full w-full md:w-[98%]">
        {/* scroll Tickets */}
        <div className="overflow-y-hidden md:overflow-y-auto  overflow-x-auto md:overflow-x-hidden flex flex-row md:flex-col md:gap-y-4 md:w-[25%] scrollbar-hide mb-[39px] relative md:h-[80vh]">
          {tickets.tickets && currentIndex < tickets.tickets.length ? (
            tickets.tickets.map((ticket, index) => {
              return (
                <TicketTiles
                  isCurrent={index === currentIndex}
                  ticket={ticket}
                  key={index}
                  onClick={() => {
                    setCurrentIndex(index);
                    setCurrentTicketId(ticket.id);
                  }}
                />
              );
            })
          ) : (
            <p className="text-blackColor">No Tickets Found</p>
          )}
        </div>

        {/* ticket details */}
        {tickets.tickets && currentIndex < tickets.tickets.length && (
          <div className="w-full md:w-[45%] mb-[39px] md:h-[80vh]  overflow-y-auto scrollbar-hide ">
            <div className="mb-2 mr-7  md:mr-0">
              <p className="text-30px font-bold text-blackColor ">
                {tickets.tickets[currentIndex].title}
              </p>
              <p className=" text-16px font-medium mb-3">
                {tickets.tickets[currentIndex].email}
              </p>
              <div className="md:flex items-center justify-between  mb-8 mr-8">
                <p className=" text-16px font-normal  mr-32">
                  {`Sent ${
                    tickets.tickets[currentIndex].createdAt
                      ? formatDateAgo(tickets.tickets[currentIndex].createdAt)
                      : ""
                  }`}
                </p>
              </div>
            </div>

            <div className=" rounded-[12px] p-5 md:p-7 mb-[24px] bg-lightGrey/20 mt-10  ">
              <p className=" text-16px font-normal">
                {tickets.tickets[currentIndex].description}
                {tickets.tickets[currentIndex].attachments.length &&
                  tickets.tickets[currentIndex].attachments.map(
                    (attachment, index) => (
                      <a
                        key={index}
                        href={attachment}
                        target="_blank"
                        className="mt-10"
                      >
                        <p className="text-[10px] text-blue-600">{`Download file ${
                          index + 1
                        }`}</p>
                      </a>
                    )
                  )}
              </p>
            </div>
            {loadingSingleTicket ? (
              <Loader isSmall={true} />
            ) : (
              <div>
                {singleTicketData &&
                  singleTicketData.data.messages.length > 0 && (
                    <p className="font-semibold mb-3">Message Threads</p>
                  )}
                {
                  <div className="mb-10">
                    {singleTicketData &&
                      singleTicketData.data.messages.map((message, index) => (
                        <div
                          key={index}
                          className=" rounded-[12px] px-5 md:px-7 mb-[7px] py-3.5 bg-lightGrey/20   "
                        >
                          <div className="flex items-center mb-3 ">
                            <div>
                              <img
                                className="h-[51px] w-[51px] object-cover rounded-full mr-[12px]"
                                src={
                                  message.sender.profile_picture
                                    ? message.sender.profile_picture
                                    : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
                                }
                                alt="user avatar"
                              />
                            </div>
                            <div className="">
                              <p className=" text-16px font-medium ">
                                {`${message.sender.fullname}`}
                              </p>
                              <p className=" text-[10px] font-normal ">
                                {message.createdAt
                                  ? formatDateAgo(message.createdAt)
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <p className=" text-14px font-normal">
                            {message.message}
                          </p>
                          {message.attachments.length &&
                            message.attachments.map((attachment, index) => (
                              <a
                                key={index}
                                href={attachment}
                                target="_blank"
                                className="mt-10"
                              >
                                <p className="text-[10px] text-blue-600">{`Download file ${
                                  index + 1
                                }`}</p>
                              </a>
                            ))}
                        </div>
                      ))}
                  </div>
                }
              </div>
            )}

            {/* text area */}
            {tickets.tickets[currentIndex].status !== null &&
              tickets.tickets[currentIndex].status !== "closed" && (
                <div>
                  <InputWithFullBoarder
                    label={"Reply to ticket"}
                    isTextArea={true}
                    className={"mb-4"}
                    value={reply}
                    onChange={(e) => setReply(e.target.value)}
                  />
                  <div className="flex items-center justify-start mb-10 w-full ">
                    <input
                      ref={inputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const files = Array.from(e.target.files);
                        if (files.length > 0) {
                          setMedia(files);
                          setMediaPicked(true);
                        } else {
                          setMedia([]);
                          setMediaPicked(false);
                        }
                      }}
                    />
                    <div className="flex flex-col md:flex-row items-center justify-start w-full">
                      <CustomButton
                        isLoading={fileLoading && uploadingMedia}
                        loader={"uploading file"}
                        onClick={handleClick}
                        className={"mr-4 text-[15px]"}
                        buttonText={"Attachments"}
                      />
                      <p className=" text-14px  text-black font-normal">
                        {media.length > 0
                          ? `${media.length} files selected`
                          : "Upload File"}
                      </p>
                    </div>
                  </div>
                  <CustomButton
                    buttonText={"Reply"}
                    isLoading={isLoading}
                    onClick={async () => {
                      const galleryUrls = mediaPicked
                        ? await handleMediaUpload()
                        : [];
                      console.log(`the uploaded gallery ${galleryUrls}`);
                      // Dynamically determine media type based on URL

                      const details = {
                        ticket: currentTicketId,
                        message: reply,
                        attachments: galleryUrls,
                      };

                      await postCaller(details);
                      setReply("");
                    }}
                  />
                </div>
              )}
          </div>
        )}

        {/* send tickets */}
        <div className="w-full md:w-[45%] px-4 md:px-0 text-left">
          <h2 className="text-2xl font-bold my-7 mb-10">Support Page</h2>
          <p className="mb-9">
            Please fill out the form and submit to reach the support team.
          </p>

          <InputWithFullBoarder
            id={"title"}
            label={"Subject"}
            type={"text"}
            value={subject}
            onChange={handleSubjectChange}
          />
          {/* <InputWithFullBoarder
              id={"email"}
              label={"Your Email"}
              type={"text"}
              value={email}
              onChange={handleEmailChange}
            /> */}

          <InputWithFullBoarder
            id={"supportRequest"}
            label={"Support Request Detail"}
            type={"textarea"}
            value={supportRequest}
            rows={6}
            isTextArea={true}
            onChange={handleSupportRequestChange}
            className={"h-[200px]"}
          />
          <div className="flex items-center justify-start mb-10 w-full ">
            <input
              ref={inputNewSupportRef}
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={(e) => {
                const files = Array.from(e.target.files);
                if (files.length > 0) {
                  setNewSupportMedia(files);
                  setNewSupportMediaPicked(true);
                } else {
                  setNewSupportMedia([]);
                  setNewSupportMediaPicked(false);
                }
              }}
            />
            <div className="flex flex-col md:flex-row items-center justify-start w-full">
              <CustomButton
                isLoading={fileLoading && uploadingNewSupportMedia}
                loader={"uploading file"}
                onClick={handleNewSupportClick}
                className={"mr-4 text-[15px]"}
                buttonText={"Attachments"}
              />
              <p className=" text-14px  text-black font-normal">
                {newSupportMedia.length > 0
                  ? `${newSupportMedia.length} files selected`
                  : "Upload File"}
              </p>
            </div>
          </div>

          <CustomButton
            buttonText={`Submit`}
            onClick={handleSubmit}
            isLoading={creatingMessage}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default UserTicketsPage;
