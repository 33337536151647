import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useGetSingleProfileManager from "../controllers/getSingleProfileController";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import UserInformationBox from "../../../../generalComponents/UserInformationBox";
import MeasurementProfileDetails from "../../../../generalComponents/MeasurementProfileDetails";
import EditMeasurementComponent from "../../../measurements/view/EditMeasurementComponent";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import Loader from "../../../../generalComponents/Loader";
import useGetListOfMetricsManager from "../../metrics/controllers/getListOfMetricsController";
import { formatDistanceToNow } from "date-fns";
import useGetProfileHistoryManager from "../controllers/getProfileHistoryController";

const ProfileDetailsPage = () => {
  function formatDateAgo(date) {
    const options = { addSuffix: true };
    return formatDistanceToNow(new Date(date), options);
  }
  const { profileId } = useParams();
  const {
    isLoading,
    data,
    isError,
    error,
    isSuccess: profileLoaded,
  } = useGetSingleProfileManager(profileId);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentMetricIndex, setCurrentMetricsIndex] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [sexIndex, setSexIndex] = useState(0);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [getHistory, setGetHistory] = useState(false);
  const [currentProfile, setCurrentProfile] = useState("");
  const [historyMode, setHistoryMode] = useState(false);

  const {
    isLoading: loadingHistory,
    data: history,
    isError: historyHasError,
    error: historyError,
  } = useGetProfileHistoryManager(currentProfile, getHistory);
  const {
    isError: listhasError,
    data: list,
    error: listError,
    isSuccess,
    isLoading: listLoading,
  } = useGetListOfMetricsManager();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setSexIndex(data.data.sex === "male" ? 1 : 0);
    }
  }, [list, isLoading, isSuccess]);

  useEffect(() => {
    if (!isLoading && profileLoaded) {
      setGetHistory(true);
      setCurrentProfile(data.data.id);
    }
  }, [data, isLoading, profileLoaded]);

  if (isLoading || listLoading) {
    return <Loader />;
  }

  if (isError || listhasError) {
    return (
      <div>
        {listhasError && <ErrorManager errorMessage={listError.message} />}
        {isError && <ErrorManager errorMessage={error.message} />}
      </div>
    );
  }
  return (
    <BaseDashboardNavigation title={"Measurement Profiles"} hideSearch={true}>
      {
        <div className="md:flex md:space-x-[30px] space-y-20 md:space-y-5 relative h-full w-[98%] mr-7">
          {/* profiles details */}
          <div className="flex flex-col md:h-[80vh] md:w-[50%] md:overflow-y-scroll scrollbar-hide mr-7 relative md:mr-0 order-3 md:order-none">
            {/* user profile details sections */}
            <UserInformationBox
              userDetails={data.data}
              isPersonal={false}
              inEditMode={editMode}
              inHistoryMode={historyMode}
              onHistoryClick={() => setHistoryMode(!historyMode)}
              onClick={() => setEditMode(!editMode)}
            />
            {/* measurement Image */}
            <div className="  md:hidden rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 h-[400px] md:h-[80vh] w-full md:w-[26%] mr-7 relative md:mr-0 ">
              <img
                className="object-contain h-full w-full"
                src={
                  list.data[sexIndex].metric.measurements[currentMetricIndex]
                    .measurement.image
                }
                alt=""
              />
            </div>

            {/* profile details */}
            {editMode ? (
              <EditMeasurementComponent
                unitName={data.data.unit}
                isCM={data.data.unit === "cm"}
                measurement={data.data.measurement}
                profileId={data.data.id}
                onUpdateIndex={(newIndex) => setCurrentMetricsIndex(newIndex)}
              />
            ) : historyMode ? (
              loadingHistory ? (
                <Loader />
              ) : history.data.length ? (
                <div>
                  <div className="flex items-center justify-normal carousel rounded-box">
                    {history.data.map((historyItem, index) => (
                      <button
                        onClick={() => setHistoryIndex(index)}
                        className="bg-offCoffee p-2 mr-5 mb-5 text-blackColor shadow-lg rounded-lg carousel-item"
                      >
                        {formatDateAgo(historyItem.createdAt)}
                      </button>
                    ))}
                  </div>
                  <MeasurementProfileDetails
                    unitName={history.data[historyIndex].unit}
                    isCM={history.data[historyIndex].unit === "cm"}
                    measurements={history.data[historyIndex].measurement}
                    onUpdateIndex={(newIndex) =>
                      setCurrentMetricsIndex(newIndex)
                    }
                  />
                </div>
              ) : (
                <p className="text-blackColor">No history found</p>
              )
            ) : (
              <MeasurementProfileDetails
                unitName={data.data.unit}
                isCM={data.data.unit === "cm"}
                measurements={data.data.measurement}
                onUpdateIndex={(newIndex) => setCurrentMetricsIndex(newIndex)}
              />
            )}
          </div>

          {/* measurement Image */}
          <div className=" hidden md:flex rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 h-[400px] md:h-[80vh] w-full md:w-[26%] mr-7 relative md:mr-0 ">
            <img
              className="object-contain h-full w-full"
              src={
                list.data[sexIndex].metric.measurements[currentMetricIndex]
                  .measurement.image
              }
              alt=""
            />
          </div>
        </div>
      }
    </BaseDashboardNavigation>
  );
};

export default ProfileDetailsPage;
