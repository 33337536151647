import { useMutation, useQueryClient } from "react-query";

import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";
import axios from "axios";
import { toast } from "react-toastify";
const baseURL = process.env.REACT_APP_BASE_URL;

const useUploadBulkProfilesManager = () => {
  const queryClient = useQueryClient();

  const postController = async (details) => {
    try {
      const response = await axios.post(
        `${baseURL}/user/profiles/upload`,
        details,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"), // Add the token header here
          },
        }
      );
      console.log(`i am checking this ${response.data.message}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  };

  const mutation = useMutation(postController, {
    onSuccess: async (data) => {
      // Update other caches using useQuery
      toast.success(data.message);
      const updateQueryKeys = ["allUserProfiles"];
      if (updateQueryKeys.length) {
        updateQueryKeys.forEach((key) => queryClient.invalidateQueries(key));
      }
    },
    onError: (error) => {
      toast.error(error.message);
      // Handle error if necessary
      console.error("Post error:", error);
      throw new Error(`Sorry: ${error.response.data.message}`);
    },
  });

  const postCaller = async (details) => {
    try {
      await mutation.mutateAsync(details);
    } catch (error) {
      // Handle error if necessary
      console.error("Post error:", error);
    }
  };

  return {
    postCaller,
    data: mutation.data,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useUploadBulkProfilesManager;
