import React from "react";
import { format } from "date-fns";
import GlobalVariables from "../constants/GlobalVariables";
import useAdoptProfileManager from "../modules/settings/controllers/adoptProfileController";
import CustomButton from "./Button";

const MeasurementProfilesTiles = ({
  profiles,
  onClick,
  isForYou,
  isCurrent,
}) => {
  const { updateCaller, isLoading, isSuccess, data, error } =
    useAdoptProfileManager();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM d, yyyy hh:mma");
  };

  const handleSubmit = async () => {
    await updateCaller(profiles.id);
  };

  return (
    <div
      className={`${
        isCurrent
          ? "bg-lightGrey/80 border-offCoffee/40 shadow-xl text-blackColor"
          : "bg-white/40 border-lightGrey/40 text-blackColor"
      } rounded-[20px] md:max-w-[399px] mr-3 md:mr-0 mb-3 flex items-end justify-between p-5  border-[2px] duration-200 `}
    >
      <div className=" flex flex-col w-[160px] md:w-auto  items-start justify-between">
        {isForYou && (
          <div onClick={onClick}>
            <img
              className=" h-[55px] md:h-[63px]  w-[55px] md:w-[63px] rounded-full object-cover  mb-3 mr-8"
              src={
                profiles.creator.profile_picture
                  ? profiles.creator.profile_picture
                  : GlobalVariables.defaultProfilePicture
              }
              alt=""
            />
          </div>
        )}
        <div>
          <p className="text-[13px] font-normal ">
            {isForYou ? `Created by` : ""}
          </p>
          <p onClick={onClick} className="text-[24px] font-semibold mb-2">
            {isForYou ? profiles.creator.fullname : profiles.fullname}
          </p>
          {/* <p className="text-[16px] font-normal ">juliaanna@gmail.com</p> */}
          <p onClick={onClick} className="text-[13px] font-normal ">
            {`Updated ${formatDate(profiles.updatedAt)}`}
          </p>
        </div>
      </div>
      {error && <p className="text-red-500">{error.message}</p>}
      {isSuccess && <p className="text-green-500">{data.message}</p>}
      <CustomButton
        onClick={isForYou ? handleSubmit : onClick}
        buttonText={isForYou ? "Adopt" : "View"}
        isLoading={isLoading}
      />
    </div>
  );
};

export default MeasurementProfilesTiles;
