import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import MeasurementProfilesTiles from "../../../generalComponents/MeasurementProfilesTiles";
import UserInformationBox from "../../../generalComponents/UserInformationBox";
import MeasurementProfileDetails from "../../../generalComponents/MeasurementProfileDetails";
import { measurementImage } from "../../../assets/images";
import useGetListofCreatedProfiles from "../controllers/getListofCreatedProfiles";
import Loader from "../../../generalComponents/Loader";
import ErrorManager from "../../../generalComponents/ErrorManager";
import CustomButton from "../../../generalComponents/Button";
import { useNavigate } from "react-router-dom";
import EditMeasurementComponent from "./EditMeasurementComponent";
import useGetListOfMetricsManager from "../../admin/metrics/controllers/getListOfMetricsController";
import useGetProfileHistoryManager from "../../admin/profiles/controllers/getProfileHistoryController";
import { formatDistanceToNow } from "date-fns";

import InfiniteScroll from "react-infinite-scroll-component";

const AllMeasurementProfiles = () => {
  function formatDateAgo(date) {
    const options = { addSuffix: true };
    return formatDistanceToNow(new Date(date), options);
  }

  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [currentMetricIndex, setCurrentMetricsIndex] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [getHistory, setGetHistory] = useState(false);
  const [currentProfile, setCurrentProfile] = useState("");
  const [historyMode, setHistoryMode] = useState(false);
  const [sexIndex, setSexIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showEditFeedback, setShowEditFeedback] = useState(false);
  const {
    isLoading,
    data,
    isError,
    error,
    refetch: fetchAgain,
  } = useGetListofCreatedProfiles(searchQuery, activePage);
  const {
    isLoading: loadingHistory,
    data: history,
    isError: historyHasError,
    error: historyError,
    refetch,
  } = useGetProfileHistoryManager(currentProfile, getHistory);
  const {
    isError: listhasError,
    data: list,
    error: listError,
    isSuccess,
    isLoading: listLoading,
  } = useGetListOfMetricsManager();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        setSearchQuery(`&fullname=${searchTerm}`);
        refetch();
      } else {
        setSearchQuery(""); // Clear the search query
        refetch();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const [items, setItems] = useState([]);
  useEffect(() => {
    fetchAgain();
  }, [activePage]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (data.data.profiles.length) {
        setSexIndex(data.data.profiles[currentIndex].sex === "male" ? 1 : 0);
        // setItems((prevItems) => [...prevItems, ...data.data.profiles]);
        setGetHistory(true);
        setCurrentProfile(data.data.profiles[currentIndex].id);
      }
    }
  }, [list, isLoading, isSuccess]);

  if (isLoading || listLoading) {
    return <Loader />;
  }

  if (isError || listhasError) {
    return (
      <div>
        {listhasError && <ErrorManager errorMessage={listError.message} />}
        {isError && <ErrorManager errorMessage={error.message} />}
      </div>
    );
  }
  return (
    <BaseDashboardNavigation
      title={"Measurement Profiles"}
      value={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        console.log(searchTerm);
      }}
    >
      {data.data.profiles.length === 0 ? (
        <div className="w-full md:h-[80vh] flex flex-col items-center justify-center ">
          <p>
            {searchTerm.length
              ? "No profile matching your search"
              : "You haven't created any Measurement Profile yet! Please create one to get started."}
          </p>
          {!searchTerm.length && (
            <CustomButton
              buttonText={"Create New Profile"}
              onClick={() => navigate("/dashboard/new-profile")}
              className={"mt-10"}
            />
          )}
        </div>
      ) : (
        <div className="md:flex md:space-x-[30px] space-y-20 md:space-y-5 relative h-full w-[98%] mr-7">
          {/* profiles scroll */}
          <div className="overflow-y-hidden md:overflow-y-auto  overflow-x-auto md:overflow-x-hidden flex flex-row md:flex-col md:gap-y-4  md:w-[40%] scrollbar-hide mb-[39px] mt-[20px] relative md:h-[75vh] order-1 md:order-none">
            {data.data.profiles.map((measurement, index) => {
              return (
                <MeasurementProfilesTiles
                  key={index}
                  profiles={measurement}
                  isForYou={false}
                  isCurrent={currentIndex === index}
                  onClick={() => {
                    setCurrentIndex(index);
                    setCurrentProfile(measurement.id);
                    refetch();
                    setShowEditFeedback(false);
                    console.log(measurement.id);
                  }}
                />
              );
            })}
            <div className="flex items-center justify-between max-w-[399px]">
              {activePage > 1 && (
                <CustomButton
                  buttonText={`Back`}
                  onClick={() => {
                    if (activePage > 1) {
                      setActivePage((prevPage) => prevPage - 1);
                    }
                  }}
                />
              )}
              {data.data.pagination.nextPage && (
                <CustomButton
                  buttonText={`Next`}
                  onClick={() => {
                    setActivePage((prevPage) => prevPage + 1);
                  }}
                />
              )}
            </div>
          </div>

          {/* profiles details */}
          <div className="flex flex-col md:h-[80vh] md:w-[50%] md:overflow-y-scroll scrollbar-hide mr-7 relative md:mr-0 order-3 md:order-none">
            {/* user profile details sections */}
            <UserInformationBox
              userDetails={data.data.profiles[currentIndex]}
              isPersonal={false}
              inEditMode={editMode}
              inHistoryMode={historyMode}
              onHistoryClick={() => setHistoryMode(!historyMode)}
              onClick={() => {
                setEditMode(!editMode);
                setShowEditFeedback(true);
              }}
            />

            {/* measurement Image */}
            <div className="  md:hidden rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 h-[400px] md:h-[80vh] w-full md:w-[26%] mr-7 relative md:mr-0 ">
              <img
                className="object-contain h-full w-full"
                src={
                  list.data[sexIndex].metric.measurements[currentMetricIndex]
                    .measurement.image
                }
                alt=""
              />
            </div>

            {/* profile details */}
            {editMode ? (
              <EditMeasurementComponent
                showEditFeedback={showEditFeedback}
                isCM={data.data.profiles[currentIndex].unit === "cm"}
                unitName={data.data.profiles[currentIndex].unit}
                measurement={data.data.profiles[currentIndex].measurement}
                profileId={data.data.profiles[currentIndex].id}
                onUpdateIndex={(newIndex) => setCurrentMetricsIndex(newIndex)}
              />
            ) : historyMode ? (
              loadingHistory ? (
                <Loader />
              ) : history.data.length ? (
                <div>
                  <div className="flex items-center justify-normal carousel rounded-box">
                    {history.data.map((historyItem, index) => (
                      <button
                        onClick={() => setHistoryIndex(index)}
                        className="bg-offCoffee p-2 mr-5 mb-5 text-blackColor shadow-lg rounded-lg carousel-item"
                      >
                        {formatDateAgo(historyItem.createdAt)}
                      </button>
                    ))}
                  </div>
                  <MeasurementProfileDetails
                    isHistory={true}
                    isCM={history.data[historyIndex].unit === "cm"}
                    measurements={history.data[historyIndex].measurement}
                    onUpdateIndex={(newIndex) =>
                      setCurrentMetricsIndex(newIndex)
                    }
                  />
                </div>
              ) : (
                <p className="text-blackColor">No history found</p>
              )
            ) : (
              <MeasurementProfileDetails
                isHistory={false}
                unitName={data.data.profiles[currentIndex].unit}
                isCM={data.data.profiles[currentIndex].unit === "cm"}
                measurements={data.data.profiles[currentIndex].measurement}
                onUpdateIndex={(newIndex) => setCurrentMetricsIndex(newIndex)}
              />
            )}
          </div>

          {/* measurement Image */}
          <div className=" hidden md:flex rounded-[12px] p-5 md:p-10 mb-[24px] bg-lightGrey/20 h-[400px] md:h-[80vh] w-full md:w-[26%] mr-7 relative md:mr-0 order-2 md:order-none">
            <img
              className="object-contain h-full w-full"
              src={
                list.data[sexIndex].metric.measurements[currentMetricIndex]
                  .measurement.image
              }
              alt=""
            />
          </div>
        </div>
      )}
    </BaseDashboardNavigation>
  );
};

export default AllMeasurementProfiles;
