import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;
console.log(baseURL);

const AxiosWithToken = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
});

let retryCount = 0;

AxiosWithToken.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (!token && retryCount < 3) {
      // Retry the request after 100 milliseconds if the token is not available
      await new Promise((resolve) => setTimeout(resolve, 100));
      retryCount++;
      return AxiosWithToken.request(config);
    }
    if (!token && retryCount === 3) {
      // Send the request without a token after 3 retries
      return config;
    }
    config.headers.Authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosWithToken.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Error response:", error.response); // Log the error response for debugging
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      // Perform redirection without React Router
      window.location.href = "/login"; // Redirect to the login page
      toast.error("You need to login to continue"); // Show a toast message
    }
    return Promise.reject(error);
  }
);

export default AxiosWithToken;
